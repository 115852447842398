import React, { useEffect, useState, useContext, useRef } from "react";
import { createPortal } from "react-dom";
import { Container, Row, Col, Toast } from "react-bootstrap";
import { MainContext } from "../../contexts/context";
import Hero from "../../Hero/Hero";
import TableOfContents from "../../TableOfContents/TableOfContents";
import IconCardsGroup from "../../IconCardsGroup/IconCardsGroup";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Icon from "../../Icon/Icon";
import Link from "../../Link/Link";
import { Accordion } from "@takamol/qiwa-design-system/components";
import { PageLoader } from "../../Loader/PageLoader";
import "./Article.scss";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import TimeDisplay from "../Articles/ArticleContainer/Sections/TimeDisplay/TimeDisplay";
import NotFoundError from "../ErrorPages/NotFound/NotFound";
import useFetchContent from "../../../hooks/useFetchContent";

export const addElementToString = (string, element, positionFromEnd) => {
  const totalStringLength = string.length;
  let stringFirstHalf = string.substring(
    0,
    totalStringLength - positionFromEnd
  );
  let stringSecondHalf = string.substring(
    totalStringLength - positionFromEnd,
    totalStringLength
  );
  return stringFirstHalf + element + stringSecondHalf;
};

const ArticlePage = () => {
  const [pageData, setPageData] = useState({});
  const [heroData, setHeroData] = useState({});
  const [publishDate, setPublishDate] = useState("");
  const [headings, setHeadings] = useState([]);
  const { attributes } = pageData;
  const { setCurrentBreadcrumb} =
    useContext(MainContext);
  const contentRef = useRef(null);
  let location = useLocation();
  const [showToast, setShowToast] = useState(false);
  const storedLanguage = localStorage.getItem("i18nextLng");
  const { t } = useTranslation("translation", { keyPrefix: "contact" });
  const { t: t2 } = useTranslation("translation", { keyPrefix: "article" });
  const [openIndexFirstAccordion, setOpenIndexFirstAccordion] = useState(1);
  const [openIndexSecondtAccordion, setOpenIndexSecondAccordion] = useState(1);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isNotFound, setIsNotFound] = useState(false);
  const { contentByType } = useFetchContent();

  useEffect(() => {
    if (location.hash === "") {
      window.scrollTo({ top: 0, behavior: "instant" });
    } else {
      setTimeout(() => {
        const id = decodeURI(location.hash.substring(1));
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 500);
    }
  }, [headings]);

  const handleFirstAccordionChange = (index) => {
    setOpenIndexFirstAccordion(
      openIndexFirstAccordion === index ? null : index
    );
  };
  const handleSecondAccordionChange = (index) => {
    setOpenIndexSecondAccordion(
      openIndexSecondtAccordion === index ? null : index
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      let apiFilter = "";

      switch (location.pathname) {
        case "/" + storedLanguage + "/terms-and-conditions":
          apiFilter = "?filter[nid][value]=56";
          break;
        case "/" + storedLanguage + "/contact":
          apiFilter = "?filter[nid][value]=57";
          break;
        case "/" + storedLanguage + "/privacy-policy":
          apiFilter = "?filter[nid][value]=58";
          break;
        case "/" + storedLanguage + "/terms-and-conditions-app":
          apiFilter = "?filter[nid][value]=319";
          break;
        case "/" + storedLanguage + "/privacy_app":
          apiFilter = "?filter[nid][value]=320";
          break;
        case "/" + storedLanguage + "/terms-and-conditions/qiwa-wallet":
          apiFilter = "?filter[nid][value]=469";
          break;
        case "/" + storedLanguage + "/contact-terms-of-use":
          apiFilter = "?filter[nid][value]=482";
          break;
        default:
          apiFilter = "";
      }

      const baseURL =
        process.env.REACT_APP_FULL_URL +
        (storedLanguage === "ar" ? "/ar" : "") +
        "/jsonapi/node/kc_article_page";

      try {
        let resp = {};
        if (!isEmpty(apiFilter)) {
          const response = await axios.get(baseURL + apiFilter);
          resp = response;
        }
        if (isEmpty(resp.data.data[0])){
          setIsDataLoading(false);
          setIsNotFound(true);
        }
        if (resp && resp.data && resp.data.data[0]) {
          const componentURL = `${baseURL}/${resp.data.data[0].id}/field_kc_article_components`;
          const response = await axios.get(componentURL);
          if (response && response.data && response.data.data[0]) {
            setHeroData(response.data.data[0].attributes);
            setIsDataLoading(false);
            setIsNotFound(false);
          }

          setPageData(resp.data.data[0]);
          setCurrentBreadcrumb(resp.data.data[0]?.attributes.title);

          const date = new Date(resp.data.data[0]?.attributes.changed);
          const options = { day: "numeric", year: "numeric", month: "long" };
          setPublishDate(
            date.toLocaleDateString(
              storedLanguage === "ar" ? "ar-EG" : "en-GB",
              options
            )
          );
        }
      } catch (error) {
        console.error("There was an error fetching the data", error);
        setHeroData({});
      }
    };

    fetchData();
    setHeroData({});
  }, [location, storedLanguage]);

  const handleClick = (e) => {
    e.target.firstChild.innerText = t2("copied");
    e.target.classList.add('copied-icon-wrapper');
    setShowToast(true);
    navigator.clipboard.writeText(
      window.location.origin +
        window.location.pathname +
        "#" +
        e.target.parentNode.id
    );
  };

  const handleMouseOut = (e) => {
    e.target.classList.remove('copied-icon-wrapper');
    e.target.firstChild.innerText = t2("copyText")
  }

  useEffect(() => {
    if(contentRef.current){
    const headingsArray = contentRef.current.querySelectorAll(
      "h2:not(.accordion-header),h3"
    );
    setHeadings(headingsArray);
    headingsArray.forEach((heading) => {
      const headingText = heading.innerText;
      const headingId = headingText.split(" ").join("-").replace(":", "");
      heading.id = headingId;
      if (heading.querySelectorAll(".copy-icon-wrapper").length === 0) {
        const headingLastWord = headingText.split(" ").pop();
        const maxWidth = window.innerWidth - 32;
        const headingWidth = heading.offsetWidth;
        if (headingWidth >= maxWidth - 35) {
          heading.innerHTML = addElementToString(
            headingText,
            "<br />",
            headingLastWord.length
          );
        }
        const spanEl = document.createElement("span");
        const tooltipEl = document.createElement("span");
        spanEl.classList.add("copy-icon-wrapper");
        tooltipEl.classList.add("copy-icon-tooltip");
        tooltipEl.innerText = t2("copyText");
        spanEl.appendChild(tooltipEl);
        spanEl.addEventListener("click", handleClick);
        spanEl.addEventListener("mouseout", handleMouseOut);
        heading.appendChild(spanEl);
      }
    });
    const imagesArray = contentRef.current.querySelectorAll("img");
    imagesArray.forEach((image) => {
      let source = image.attributes.src.value;
      image.src = process.env.REACT_APP_FULL_URL + source;
    });
  }
  }, [pageData]);

  return (
    <>
      {isDataLoading && <PageLoader />}
      {isNotFound ? <NotFoundError /> :
      <><Hero data={heroData} />
      <div
        ref={contentRef}
        className={`article-page ${
          attributes && attributes.field_show_table_of_content
            ? "article-page--with-toc"
            : ""
        }`}
        data-testid="article-test"
      >
        <Container>
          <Row>
            {attributes && attributes.field_show_table_of_content && (
              <Col lg="4">
                <TableOfContents
                  headings={headings}
                  links={attributes.field_social_links}
                  email={attributes.field_contact_email}
                  number={attributes.field_contact_number}
                />
              </Col>
            )}
            <Col lg="8" className="mx-auto article-page__content-wrapper">
              {attributes && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: attributes?.field_body?.value,
                  }}
                ></div>
              )}
              {publishDate &&
                location.pathname !== "/" + storedLanguage + "/contact" && (
                  <div className="article-page__date-wrapper">
                    <p className="article-page__publish-date">
                      <strong>{t2("publishDate")}:</strong>
                    </p>
                    <p className="article-page__publish-date">
                      {publishDate.toString()}
                    </p>
                  </div>
                )}
              <Toast
                className="toast-copy-message"
                bg={"success"}
                show={showToast}
                autohide={true}
                onClose={() => setShowToast(false)}
              >
                <Toast.Body>
                  <div className="checkmark-success">
                    <Icon name="checkmark"></Icon>
                  </div>
                  <p>{t2("copyToastMessage")}</p>
                  <button
                    className="toast-close-button"
                    onClick={() => setShowToast(false)}
                  >
                    <Icon name="close" />
                  </button>
                </Toast.Body>
              </Toast>
              {location.pathname === "/" + storedLanguage + "/contact" &&
                !isDataLoading && (
                  <div>
                    <div className="article-page__communication-channels-wrapper">
                      <div className="article-page__communication-channels-box">
                        <div className="article-page__communication-channels-box__icon-box">
                          <div className="article-page__communication-channels-box__icon-box-inner">
                            <Icon name="message" />
                          </div>
                          <div>
                            <p className="article-page__communication-channels-box__title">
                              {t("liveChat")}
                            </p>
                            <p>{t("TheFastestWay")}</p>
                            <div className="article-page__communication-channels-box__last-line">
                              <div
                                className="article-page__communication-channels-box__availability"
                                dangerouslySetInnerHTML={{
                                  __html: t("availability", {
                                    interpolation: { escapeValue: false },
                                  }),
                                }}
                              ></div>
                              <div
                                className="article-page__communication-channels-box__responseTime"
                                dangerouslySetInnerHTML={{
                                  __html: t("responseTime", {
                                    interpolation: { escapeValue: false },
                                  }),
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <button
                          className="article-page__communication-channels-box__link"
                        >
                          {t("startChat")}
                        </button>
                      </div>
                      <div className="article-page__communication-channels-box">
                        <div className="article-page__communication-channels-box__icon-box">
                          <div className="article-page__communication-channels-box__icon-box-inner">
                            <Icon name="ear" />
                          </div>
                          <div>
                            <p className="article-page__communication-channels-box__title">
                              {t("signLanguageSupport")}
                            </p>
                            <p>{t("aVideoCall")}</p>
                            <div className="article-page__communication-channels-box__last-line">
                              <div
                                className="article-page__communication-channels-box__availability"
                                dangerouslySetInnerHTML={{
                                  __html: t("availability", {
                                    interpolation: { escapeValue: false },
                                  }),
                                }}
                              ></div>
                              <div
                                className="article-page__communication-channels-box__responseTime"
                                dangerouslySetInnerHTML={{
                                  __html: t("responseTime", {
                                    interpolation: { escapeValue: false },
                                  }),
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <Link
                          to={"/customer-video-calling-service"}
                          className="article-page__communication-channels-box__link"
                        >
                          <div
                            className={"article-page__icon-children-wrapper"}
                          >
                            <Icon name="sendbox" />
                            {t("startVideoCall")}
                          </div>
                        </Link>
                      </div>
                      <div className="article-page__communication-channels-box">
                        <div className="article-page__communication-channels-box__icon-box">
                          <div className="article-page__communication-channels-box__icon-box-inner">
                            <Icon name="whatsapp-outlined" />
                          </div>
                          <div>
                            <p className="article-page__communication-channels-box__title">
                              {t("WhatsApp")}
                            </p>
                            <p>{t("getHelpFrom")}</p>
                            <div className="article-page__communication-channels-box__last-line">
                              <div
                                className="article-page__communication-channels-box__availability"
                                dangerouslySetInnerHTML={{
                                  __html: t("availability", {
                                    interpolation: { escapeValue: false },
                                  }),
                                }}
                              ></div>
                              <div
                                className="article-page__communication-channels-box__responseTime"
                                dangerouslySetInnerHTML={{
                                  __html: t("responseTime", {
                                    interpolation: { escapeValue: false },
                                  }),
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className={"article-page__link-wrapper"}>
                          <Link
                            to="https://api.whatsapp.com/send/?phone=966594172813&text=&type=phone_number&app_absent=0"
                            className="article-page__communication-channels-box__link"
                          >
                            <div
                              className={"article-page__icon-children-wrapper"}
                            >
                              <Icon name="sendbox" />
                              {t("startChat")}
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="article-page__communication-channels-box">
                        <div className="article-page__communication-channels-box__icon-box">
                          <div className="article-page__communication-channels-box__icon-box-inner">
                            <Icon name="call-outlined" />
                          </div>
                          <div>
                            <p className="article-page__communication-channels-box__title">
                              {t("phoneCall")}
                            </p>
                            <p>{t("ourAgents")}</p>
                            <div className="article-page__communication-channels-box__last-line">
                              <div
                                className="article-page__communication-channels-box__availability"
                                dangerouslySetInnerHTML={{
                                  __html: t("availability", {
                                    interpolation: { escapeValue: false },
                                  }),
                                }}
                              ></div>
                              <div
                                className="article-page__communication-channels-box__responseTime"
                                dangerouslySetInnerHTML={{
                                  __html: t("responseTime", {
                                    interpolation: { escapeValue: false },
                                  }),
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="article-page__phone-wrapper">
                          <Link to={"tel:+966920000105"}>
                            <p className="article-page__communication-channels-box__phone">
                              920000105
                            </p>
                          </Link>
                        </div>
                      </div>
                      <div className="article-page__communication-channels-box">
                        <div className="article-page__communication-channels-box__icon-box">
                          <div className="article-page__communication-channels-box__icon-box-inner">
                            <Icon name="contactMail" />
                          </div>
                          <div>
                            <p className="article-page__communication-channels-box__title">
                              {t("email")}
                            </p>
                            <p>{t("emailUs")}</p>
                            <div className="article-page__communication-channels-box__last-line">
                              <div
                                className="article-page__communication-channels-box__availability"
                                dangerouslySetInnerHTML={{
                                  __html: t("availability", {
                                    interpolation: { escapeValue: false },
                                  }),
                                }}
                              ></div>
                              <div
                                className="article-page__communication-channels-box__responseTime"
                                dangerouslySetInnerHTML={{
                                  __html: t("responseTime", {
                                    interpolation: { escapeValue: false },
                                  }),
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="article-page__phone-wrapper">
                          <Link to={"mailto:support@qiwa.sa"}>
                            <p className="article-page__communication-channels-box__phone">
                              support@qiwa.sa
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="article-page__confidentiality">
                      <div className="article-page__confidentiality__title">
                        {t("complaintEscalationProcess")}
                      </div>
                      <div className="article-page__confidentiality__accordions">
                        <Accordion
                          descriptionPlacement="inline"
                          iconPlacement="end"
                          shouldAutoScrollOnOpen={false}
                          hasBorderBottom={false}
                        >
                          <div className="article-page__confidentiality__accordion-wrapper">
                            <Accordion.Item
                              key="1"
                              isOpened={openIndexFirstAccordion === 1}
                              onClick={() => handleFirstAccordionChange(1)}
                            >
                              <Accordion.Header>
                                {t("whoCanSubmit")}
                              </Accordion.Header>
                              <Accordion.Content>
                                <div className="article-page__confidentiality__content">
                                  {t("whoCanSubmitDescription")}
                                </div>
                              </Accordion.Content>
                            </Accordion.Item>
                          </div>
                          <div className="article-page__confidentiality__accordion-wrapper">
                            <Accordion.Item
                              key="2"
                              isOpened={openIndexFirstAccordion === 2}
                              onClick={() => handleFirstAccordionChange(2)}
                            >
                              <Accordion.Header>
                                {t("submissionChannels")}
                              </Accordion.Header>
                              <Accordion.Content>
                                <div
                                  className="article-page__confidentiality__content"
                                  dangerouslySetInnerHTML={{
                                    __html: t("submissionChannelsDescription", {
                                      interpolation: { escapeValue: false },
                                    }),
                                  }}
                                />
                              </Accordion.Content>
                            </Accordion.Item>
                          </div>
                          <div className="article-page__confidentiality__accordion-wrapper">
                            <Accordion.Item
                              key="3"
                              isOpened={openIndexFirstAccordion === 3}
                              onClick={() => handleFirstAccordionChange(3)}
                            >
                              <Accordion.Header>
                                {t("timeframAndResponse")}
                              </Accordion.Header>
                              <Accordion.Content>
                                <div
                                  className="article-page__confidentiality__content"
                                  dangerouslySetInnerHTML={{
                                    __html: t(
                                      "timeframAndResponseDescription",
                                      {
                                        interpolation: { escapeValue: false },
                                      }
                                    ),
                                  }}
                                ></div>
                              </Accordion.Content>
                            </Accordion.Item>
                          </div>
                        </Accordion>
                      </div>
                    </div>
                    <div className="article-page__confidentiality">
                      <div className="article-page__confidentiality__title">
                        {t("confidentiality")}
                      </div>
                      <div className="article-page__confidentiality__accordions">
                        <Accordion
                          descriptionPlacement="inline"
                          iconPlacement="end"
                          shouldAutoScrollOnOpen={false}
                          hasBorderBottom={false}
                        >
                          <div className="article-page__confidentiality__accordion-wrapper">
                            <Accordion.Item
                              key="1"
                              isOpened={openIndexSecondtAccordion === 1}
                              onClick={() => handleSecondAccordionChange(1)}
                            >
                              <Accordion.Header>
                                {t("privacyAndData")}
                              </Accordion.Header>
                              <Accordion.Content>
                                <div className="article-page__confidentiality__content">
                                  {t("privacyAndDataDescription")}
                                </div>
                              </Accordion.Content>
                            </Accordion.Item>
                          </div>
                          <div className="article-page__confidentiality__accordion-wrapper">
                            <Accordion.Item
                              key="2"
                              isOpened={openIndexSecondtAccordion === 2}
                              onClick={() => handleSecondAccordionChange(2)}
                            >
                              <Accordion.Header>
                                {t("diclaimer")}
                              </Accordion.Header>
                              <Accordion.Content>
                                <div className="article-page__confidentiality__content">
                                  {t("diclaimerDescription")}
                                </div>
                              </Accordion.Content>
                            </Accordion.Item>
                          </div>
                          <div className="article-page__confidentiality__accordion-wrapper">
                            <Accordion.Item
                              key="3"
                              isOpened={openIndexSecondtAccordion === 3}
                              onClick={() => handleSecondAccordionChange(3)}
                            >
                              <Accordion.Header>
                                {t("purposeOfCommunicationRecording")}
                              </Accordion.Header>
                              <Accordion.Content>
                                <div className="article-page__confidentiality__content">
                                  {t(
                                    "purposeOfCommunicationRecordingDescription"
                                  )}
                                </div>
                              </Accordion.Content>
                            </Accordion.Item>
                          </div>
                        </Accordion>
                      </div>
                    </div>
                    <div className="article-page__note-wrapper">
                      <div className="article-page__note-wrapper__bar" />
                      <div className="article-page__note-wrapper__textbox">
                        <div>
                          <Icon name="note" />
                        </div>
                        <div>
                          <p>{t("WeValueYourFeedback")}</p>
                          <span>{t("yourFeedback")}</span>
                        </div>
                      </div>
                    </div>
                       <TimeDisplay createdTime={1717219200} updatedTime={1717219200}/>
                  </div>
                )}
            </Col>
          </Row>
        </Container>
      </div>
      <IconCardsGroup groupData={contentByType?.why_qiwa_platform} variant="dark" />
      </>}
    </>
  );
};

export default ArticlePage;
