import React from "react";
import PropTypes from "prop-types";
import { animated, easings, useSpringValue } from 'react-spring';

const AnimatedNumber = ({number, startAnimation, tab, activeTab}) => {
  const numberFromString = number.replace(/[^\d\\.]*/g, '');
  const roundValue = numberFromString ? Math.round(numberFromString) : 0;
  const springNumber = useSpringValue(0, {
    reset: true,
    config: {
      duration: 2000,
      easing: easings.easeInCubic,
    }}
  )

  if (startAnimation && tab === activeTab) {
    springNumber.start({
      from: 0,
      to: roundValue
    })
  } else if (tab === activeTab) {
    springNumber.set(roundValue)
  }

  return (
    <animated.div className="numbers-block__number">
      {springNumber.to(val => Math.floor(val))}
    </animated.div>
  )
}

AnimatedNumber.propTypes = {
  number: PropTypes.string, 
  startAnimation: PropTypes.bool, 
  tab: PropTypes.string,
  activeTab: PropTypes.string, 
};

export default AnimatedNumber;
