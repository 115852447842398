import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import { MainContext } from "../contexts/context";
import "./IconCard.scss";

const IconCard = ({ icon, description, type, index, number }) => {
  const [isTablet, setIsTablet] = useState(window.innerWidth < 992);
  const { theme } = useContext(MainContext);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getFormattedDescription = (description, number, index) => {
    const words = description.split(" ");
    const isStatisticsType = type === "statistics";
    const isArabicTheme = theme === "arabic" && !isTablet && isStatisticsType;

    const formatWithNumber = (number, text) => `<span>${number}</span> ${text}`;
    const addLineBreak = (text, wordCount) =>
      `${words.slice(0, wordCount).join(" ")}<br>${words.slice(wordCount).join(" ")}`;

    const getArabicFormattedDescription = () => {
      if (index === 0 && words.length > 2) return formatWithNumber(number, addLineBreak(description, 2));
      if (index >= 1 && index <= 5 && words.length > 3) return formatWithNumber(number, addLineBreak(description, 3));
      return formatWithNumber(number, description);
    };

    const getStandardFormattedDescription = () => {
      if (index <= 3 && words.length > 1 && !isTablet) return formatWithNumber(number, addLineBreak(description, 1));
      if (index === 4 || (index === 5 && words.length > 2 && !isTablet)) return formatWithNumber(number, description);
      if (number) return formatWithNumber(number, description);
      return description;
    };

    if (isTablet) {
      return formatWithNumber(number, description);
    }

    if (isArabicTheme) return getArabicFormattedDescription();
    return isStatisticsType ? getStandardFormattedDescription() : description;
  };

  return (
    <div className={`icon-card ${type}`} data-testid="icon-card-test">
      <div className="icon-card__icon-container">
        <img src={icon} alt="icon" />
      </div>
      <div
        className="icon-card__description"
        dangerouslySetInnerHTML={{
          __html: getFormattedDescription(description, number, index),
        }}
      />
    </div>
  );
};

IconCard.propTypes = {
  icon: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  index: PropTypes.number,
  number: PropTypes.number,
};

IconCard.defaultProps = {
  icon: "desktop",
  description: "",
  type: "whyUsing",
};

export default IconCard;
