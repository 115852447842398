import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Breadcrumbs from "../../../Breadcrumbs/Breadcrumbs";
import "./NotFound.scss";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { MainContext } from "../../../contexts/context";
import HeroSearchInput from "../../../Search/HeroSearchInput/HeroSearchInput.tsx";
import { fetchErrorData } from "../../../../FetchError.js";

function NotFoundError({ code }) {
  const { theme } = useContext(MainContext);
  const [errorData, setErrorData] = useState({});
  const { t } = useTranslation("translation", {
    keyPrefix: "errors.404error",
  });

  useEffect(() => {
    const fetchData = async () => {
      const fetchCode = code === 404 ? "404?include=field_relevant_page" : code;
      const data = await fetchErrorData(fetchCode);
      if (localStorage.getItem("i18nextLng") === "ar") {
        if (data && data.data) {
          setErrorData(data.data[1]);
        }
      } else {
        if (data && data.data) {
          setErrorData(data.data[0]);
        }
      }
    };

    fetchData();
  }, [code, localStorage.getItem("i18nextLng")]);

  const { attributes } = errorData;

  return (
    <Container fluid>
      <Row
        className={classnames({
          "not-found-main": true,
          "not-found-main--rtl": theme !== "english",
        })}
      >
        <Col className="not-found-content">
          <Breadcrumbs />
          <p className="not-found-header">{attributes?.field_label}</p>
          <p className="not-found-error-code">{t("errorCode", { code })}</p>
          <p
            dangerouslySetInnerHTML={{
              __html: attributes?.field_description?.value,
            }}
          ></p>
        </Col>
      </Row>
      <Row
        className={classnames({
          "not-found-search": true,
          "not-found-search--ltr": theme === "english",
          "not-found-search--rtl": theme !== "english",
        })}
      >
        <p className="not-found-search-title">{t("searchTitle")}</p>
        <Col lg={5} className="not-found-search-component">
          <HeroSearchInput
            placeholder={t("searchPlaceholder")}
            buttonText={t("buttonText")}
            isSaudiLabor={false}
            id="404-search-input-id"
          />
        </Col>
        <p className="not-found-search-header">{t("topicsHeader")}</p>
        <a href={`/${localStorage.getItem("i18nextLng")}/labor-law/non-saudis/hiring-non-saudis`}>{t("firstTopic")}</a>
        <a href={`/${localStorage.getItem("i18nextLng")}/business-owners/hire-employees/hiring-non-saudi-employees`}> {t("secondTopic")}</a>
        <a href={`/${localStorage.getItem("i18nextLng")}/business-owners/hire-employees/how-issue-work-permits-non-saudis`}>{t("thirdTopic")}</a>
        <a href={`/${localStorage.getItem("i18nextLng")}/business-owners/manage-current-employees/how-transfer-non-saudi-employees-between-your`}>{t("fourthTopic")}</a>
        <a href={`/${localStorage.getItem("i18nextLng")}/business-owners/manage-current-employees/how-renew-work-permits`}> {t("fifthTopic")}</a>
      </Row>
    </Container>
  );
}

NotFoundError.propTypes = {
  code: PropTypes.number,
};

NotFoundError.defaultProps = {
  code: 404,
};

export default NotFoundError;