import React from "react";
import AIAssistantDialogMsgActions from "../AIAssistantDialogMsgActions";
import AiAvatarSVG from "../../shared/assets/AiAvatarStaticSVG.svg";
import { Styled } from "./AIAssistantDialogMsg.styles";
import { Loader } from "@takamol/qiwa-design-system/components";

type AIAssistantDialogMsgProps = {
  author: string;
  msg: string;
  isLoading?: boolean;
  isComplete?: boolean;
};

const AIAssistantDialogMsg = ({
  author,
  msg,
  isLoading,
  isComplete,
}: AIAssistantDialogMsgProps) => {
  return (
    <Styled.MsgContainer
      $author={author}
      data-testid="ai-assistant-msg-test-id"
    >
      <Styled.MsgContent>
        {author !== "user" && (
          <Styled.MsgAvatar>
            <img src={`${AiAvatarSVG}`} alt="ai-avatar-img" />
          </Styled.MsgAvatar>
        )}
        {isLoading && !isComplete ? (
          <Loader size={20} variant="business" />
        ) : (
          <Styled.MsgText
            dangerouslySetInnerHTML={{
              __html: msg,
            }}
          />
        )}
        {author !== "user" && (
          <Styled.MsgReactionsWrapper>
            <AIAssistantDialogMsgActions msg={msg} />
          </Styled.MsgReactionsWrapper>
        )}
      </Styled.MsgContent>
    </Styled.MsgContainer>
  );
};

export default AIAssistantDialogMsg;
