import React, { useState } from "react";
import {
  IconButton,
  TextArea,
  Button,
  useToastMethodsContext,
  Tooltip,
} from "@takamol/qiwa-design-system/components";
import { Copy } from "@takamol/qiwa-design-system/icons";
import Icon from "../../../../Icon/Icon";
import { useForm } from "react-hook-form";
import { Styled } from "./AIAssistantDialogMsgActions.styles";
import { useTranslation } from "react-i18next";

type AIAssistantDialogMsgActionsProps = {
  msg?: string;
};

const AIAssistantDialogMsgActions = ({
  msg = "",
}: AIAssistantDialogMsgActionsProps) => {
  const [selectedAction, setSelectedAction] = useState<string>("");
  const [isFeedbackLoading, setIsFeedbackLoading] = useState<boolean>(false);
  const [isTooltipCopied, setIsTooltipCopied] = useState<boolean>(false);
  const { addToast } = useToastMethodsContext();
  const { register, handleSubmit, resetField } = useForm();
  const { t } = useTranslation("translation", {
    keyPrefix: "aiAssistant",
  });

  const onFeedbackSubmit = (data: any) => {
    // Simulating request
    setIsFeedbackLoading(true);
    setTimeout(() => {
      addToast({
        message: t("successFeedback"),
        variant: "success",
      });
      setIsFeedbackLoading(false);
      setSelectedAction("");
      resetField("actionFeedback");
    }, 1000);
  };

  const extractBareMsg = (msg: string) => {
    const div = document.createElement("div");
    div.innerHTML = msg;
    const text = div.textContent || div.innerText || "";

    return text;
  };

  return (
    <Styled.ActionsWrapper>
      <Styled.SingleActionWrapper>
        <IconButton
          size={24}
          variant={selectedAction === "like" ? "business_ghost" : "gray"}
          onClick={() => setSelectedAction("like")}
          iconComponent={() => (
            <>
              <Icon
                name="like"
                viewBox="-3 -2 24 24"
                height="24px"
                width="24px"
              />
            </>
          )}
        />
      </Styled.SingleActionWrapper>
      <Styled.SingleActionWrapper>
        <IconButton
          size={24}
          variant={selectedAction === "dislike" ? "business_ghost" : "gray"}
          onClick={() => setSelectedAction("dislike")}
          iconComponent={() => (
            <>
              <Icon
                name="dislike"
                viewBox="-3 -2 24 24"
                height="24px"
                width="24px"
              />
            </>
          )}
        />
      </Styled.SingleActionWrapper>

      <Styled.SingleActionWrapper>
        <Tooltip
          isVisibleOnStart={false}
          description={isTooltipCopied ? t("answerCopied") : t("copyAnswer")}
        >
          <IconButton
            variant="gray"
            size={24}
            iconComponent={Copy}
            onClick={() => {
              navigator.clipboard.writeText(extractBareMsg(msg));
              setIsTooltipCopied(true);
            }}
            onMouseLeave={() => setIsTooltipCopied(false)}
          />
        </Tooltip>
      </Styled.SingleActionWrapper>

      {selectedAction !== "" && (
        <Styled.FeedbackWrapper>
          <form onSubmit={handleSubmit(onFeedbackSubmit)}>
            <TextArea
              {...register("actionFeedback")}
              label={t("feedbackLabel")}
              id="action-feedback-id"
              inputHeight={48}
            />
            <Button
              variant="business_primary_filled"
              type="submit"
              isLoading={isFeedbackLoading}
            >
              {t("submitFeedback")}
            </Button>
          </form>
        </Styled.FeedbackWrapper>
      )}
    </Styled.ActionsWrapper>
  );
};

export default AIAssistantDialogMsgActions;
