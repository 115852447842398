import {
  VideoPlayer,
  Box,
  IconButton,
  Modal,
  Text,
  Button as QiwaButton,
  Image,
  Divider,
} from "@takamol/qiwa-design-system/components";
import { videoDataShape } from "./propShapes";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { map, toNumber } from "lodash";
import Icon from "../../../../../Icon/Icon";
import "./Videos.scss";
import Link from "../../../../../Link/Link";
import { useTranslation } from "react-i18next";

const Videos = (videoData) => {
  const data = videoData?.videoData;
  const { t } = useTranslation("translation", { keyPrefix: "videoBanner" });
  const [showModal, setShowModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);


  const Caret = () => <Icon name="play" width="24" height="24" />;

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleContactClick = () => {
    const contactUrl = `/${localStorage.getItem("i18nextLng")}${
      data?.metaInformation?.contact_us?.url
    }`;
    if (contactUrl) {
      window.open(contactUrl, "_blank");
    }
    handleClose();
  };

  const convertedData = map(data?.chapters, (item) => ({
    ...item,
    seconds: toNumber(item?.time),
  }));

  return (
    <div className="videos">
      <button className="videos__image-container" data-testid="image-container" onClick={handleOpen}>
        <Image
          altText="Meet new Qiwa Platform!"
          aspectRatio="16 / 9"
          src={data?.poster}
          borderRadius={8}
          overlay="light"
        />
      </button>

      <Box
        className="videos-second"
        direction="row"
        mt={12}
        bgColor="business_700"
        borderRadius={8}
        onClick={handleOpen}
        data-testid="watch-video-button"
      >
        <IconButton
          onClick={handleOpen}
          variant="business_ghost"
          iconComponent={Caret}
           
        />
        <button className="videos__watch-video" onClick={handleOpen}>
          {data?.metaInformation?.watch}
        </button>
        <p className="videos__duration">
          {data?.metaInformation?.duration}: {data?.duration}
        </p>
      </Box>

      <Modal
        hasCloseButton={true}
        isOpened={showModal}
        handleClose={handleClose}
        desktopWidth="extraWide"
        data-testid="video-modal"
      >
        <Modal.Body>
          <Box gap={24} className="video-inside__video-group" data-testid="video-group">
            <Text
              pe={8}
              variant={["heading-xxs", "heading-xs"]}
              weight="semibold"
              data-testid="video-title"
            >
              {data?.title}
            </Text>
            <Text variant="body-l" data-testid="video-subtitle">{data?.subtitle}</Text>
            <VideoPlayer
              withAutoplay={true}
              startTime={data?.startTime}
              chapters={convertedData}
              chaptersTitle={data?.metaInformation?.table_of_contents}
              videoSrc={data?.videoSrc}
              poster={data?.poster}
              altPosterDescription="Video poster"
              chaptersPosition="side"
              chaptersMaxHeight={320}
              data-testid="video-player"
            />
          </Box>
        </Modal.Body>
        <Modal.Footer data-testid="video-footer">
          {width >= 769 && (
            <div>
              <Divider />
              <Text className="video-inside__help">
                {data?.metaInformation?.help}{" "}
                {
                  <Link to={data?.metaInformation?.contact_us?.url}>
                    {data?.metaInformation?.contact_us?.title}
                  </Link>
                }
              </Text>
            </div>
          )}
          {width < 769 && (
            <div className="video-inside__modal-buttons">
              <QiwaButton onClick={handleClose} data-testid="close-button">{t("closeModal")}</QiwaButton>
              <QiwaButton
                variant="individuals_ghost"
                onClick={handleContactClick}
                data-testid="contact-button"
              >
                {data?.metaInformation?.help}{" "}
                {data?.metaInformation?.contact_us?.title}
              </QiwaButton>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

Videos.propTypes = {
  videoData: PropTypes.shape(videoDataShape),
};

export default Videos;
