import React from "react";
import AIAssistantHeader from "./components/AIAssistantHeader/AIAssistantHeader";
import {
  Box,
  Text,
  Icon as QiwaIcon,
} from "@takamol/qiwa-design-system/components";
import Icon from "../../Icon/Icon";
import { DigitalDocument } from "@takamol/qiwa-design-system/icons";
import { Styled } from "./AIAssistant.styles";
import AiAnimationWebm from "./shared/assets/ai-animation.webm";
// import AiAnimationWebm from "../../../../public/ai-animation.webm";
import AnimationBackground from "./shared/assets/AnimationBackground";
import AIAssistantInput from "./components/AIAssistantInput/AIAssistantInput";
import { useNavigate } from "react-router-dom";
import { manageQuestionsStorage } from "./shared/utils/manageQuestionsStorage";
import { useTranslation } from "react-i18next";

const AIAssistant = () => {
  const navigate = useNavigate();
  const { t: t1 } = useTranslation("translation", {
    keyPrefix: "aiAssistant",
  });
  const { t: t2 } = useTranslation("translation", {
    keyPrefix: "aiAssistant.questions",
  });

  const onQuestionClick = (question: string) => {
    const crypto = window.crypto;
    const randomArray = new Uint32Array(1);
    crypto.getRandomValues(randomArray);
    manageQuestionsStorage.addQuestion({ author: "user", msg: question });
    navigate(`/${localStorage.getItem("i18nextLng")}/ai-assistant-answers`, {
      replace: true,
    });
  };

  return (
    <Styled.PageWrapper
      data-testid="ai-assistant-lp-test-id"
      className="ai-assistant-page"
    >
      <AIAssistantHeader />
      <Styled.Container>
        <Styled.AnimationContainer>
          <Styled.AnimationForeground />
          <video
            autoPlay
            loop
            muted
            playsInline
            onContextMenu={(e) => e.preventDefault()}
          >
            <source src={AiAnimationWebm} />
          </video>
        </Styled.AnimationContainer>
        <Styled.PageTextContainer>
          <Styled.PageTitle>
            {t1("greetings1")}
            <br />
            {t1("greetings2")}
          </Styled.PageTitle>
          <Styled.PageDescription>
            <p>{t1("pageDesc1")}</p>
            <p>{t1("pageDesc2")}</p>
          </Styled.PageDescription>
        </Styled.PageTextContainer>
        <Styled.QuestionsContainer>
          <Styled.QuestionButton
            onClick={() => onQuestionClick(t2("visaGuide"))}
          >
            <Box direction={"row"} gap={16} align={"center"}>
              <QiwaIcon
                radiusVariant="squared"
                background="business_100"
                color="business_500"
                padding={8}
                iconComponent={DigitalDocument}
              />
              <Text variant={"body-m"} weight={"medium"}>
                {t2("visaGuide")}
              </Text>
            </Box>
          </Styled.QuestionButton>
          <Styled.QuestionButton
            onClick={() => onQuestionClick(t2("hireNonSaudi"))}
          >
            <Box direction={"row"} gap={16} align={"center"}>
              <QiwaIcon
                radiusVariant="squared"
                background="business_100"
                color="business_500"
                padding={8}
                iconComponent={() => <Icon name="customMsg" />}
              />
              <Text variant={"body-m"} weight={"medium"}>
                {t2("hireNonSaudi")}
              </Text>
            </Box>
          </Styled.QuestionButton>
          <Styled.QuestionButton
            onClick={() => onQuestionClick(t2("contract"))}
          >
            <Box direction={"row"} gap={16} align={"center"}>
              <QiwaIcon
                radiusVariant="squared"
                background="business_100"
                color="business_500"
                padding={8}
                iconComponent={DigitalDocument}
              />
              <Text variant={"body-m"} weight={"medium"}>
                {t2("contract")}
              </Text>
            </Box>
          </Styled.QuestionButton>
        </Styled.QuestionsContainer>
        <Styled.InputContainer>
          <AIAssistantInput addMsg={onQuestionClick} />
        </Styled.InputContainer>
        <Styled.FooterText>{t1("footerText")}</Styled.FooterText>
      </Styled.Container>
    </Styled.PageWrapper>
  );
};

export default AIAssistant;
