import React, { useState, useContext } from "react";
import { Field, Tooltip } from "@takamol/qiwa-design-system/components";
import { Arrow } from "@takamol/qiwa-design-system/icons";
import { Styled } from "./AIAssistantInput.styles";
import { useForm } from "react-hook-form";
import { manageQuestionsStorage } from "../../shared/utils/manageQuestionsStorage";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../../../contexts/context";
import Icon from "../../../../Icon/Icon";
import DOMPurify from "dompurify";

type AIAssistantInputProps = {
  isAnswersPage?: boolean;
  addMsg?: (question: string) => void;
  isInputDisabled?: boolean;
};

const AIAssistantInput = ({
  isAnswersPage,
  addMsg,
  isInputDisabled,
}: AIAssistantInputProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const { theme } = useContext(MainContext);

  const { t } = useTranslation("translation", {
    keyPrefix: "aiAssistant",
  });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    if (inputValue.trim() === "") {
      return;
    }

    const sanitizedInput = DOMPurify.sanitize(inputValue, {
      ALLOWED_TAGS: [ "p", "br", "b", "i", "u", "a"],
      ALLOWED_ATTR: ["style", "alt", "width", "height"],
    });
  
    if (!sanitizedInput.trim()) {
      console.log("Input was completely sanitized. No valid content to submit.");
      return;
    }

    if (addMsg) {
      addMsg(sanitizedInput);
    }
  
    if (isAnswersPage) {
      setInputValue("");
    }
  };

  const onError = (errors: any) => {
    console.log(errors);
  };

  return (
    <Styled.FormWrapper data-testid="ai-assistant-input-test-id">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Styled.InputWrapper $isAnswersPage={isAnswersPage}>
          <Styled.StarIconWrapper>
            <Icon name="aiQiwa" viewBox="0 0 21 20" height="20" width="20" />
          </Styled.StarIconWrapper>
          <Field
            {...register("questionValue", { maxLength: 25000 })}
            iconComponent={() => <></>}
            iconPlacement="start"
            onIconClick={() => {
              return;
            }}
            secondIconComponent={() => (
              <>
                {inputValue === "" ? (
                  <Tooltip description={t("emptyMessage")}>
                    <Arrow />
                  </Tooltip>
                ) : (
                  <Arrow />
                )}
              </>
            )}
            secondIconRotation={theme === "english" ? 0 : 180}
            secondIconPlacement="end"
            onSecondIconClick={() => {
              console.log("second click");
              onSubmit(getValues());
            }}
            id="ai-question-input-id"
            label={isAnswersPage ? "" : t("whatIsQuestion")}
            maxInputWidth={["100%", "734px"]}
            fieldWidth={"100%"}
            placeholder={t("placeholder")}
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
            autoComplete={"off"}
            isDisabled={isInputDisabled}
          />
        </Styled.InputWrapper>
      </form>
    </Styled.FormWrapper>
  );
};

export default AIAssistantInput;
