import React from "react";
import Card from "react-bootstrap/Card";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import Link from "../Link/Link";
import classnames from "classnames";

import "./ActionTile.scss";

const ActionTile = ({
  title,
  description,
  theme,
  tag,
  type,
  withLink,
  cta,
  layout,
}) => {
  
  return (
    <div className="action-tile-wrapper">
      <Card
        className={`action-tile action-tile--${theme} ${
          type !== "regular" && tag ? "action-tile--with-tag" : ""
        }`}
        data-testid="action-tile-card"
      >
        <Card.Body className={`${layout === "tight" && "action-tile__card-block"}`}>
          {type !== "regular" && tag && (
            <Card.Text className={`action-tile__tag action-tile__tag--${type}`}>
              <Icon
                name="radio-checkbox"
                viewBox="0 0 64 64"
                height="10"
                width="10"
                className="action-tile__tag-icon"
              />
              <span>{tag}</span>
            </Card.Text>
          )}
          <Card.Title
            className={`${layout === "tight" && "action-tile__title-tight"}`}
          >
            {title}
          </Card.Title>
          {description && <Card.Text
            className={classnames(
              layout === "tight" && "action-tile__text-tight",
              "action-tile__tile-description"
            )}
          >
            {description}
          </Card.Text>}
          {withLink && (
            <Card.Link
              as={(props) => (
                <Link
                  {...props}
                  textLink={true}
                  underlined={true}
                  variant="business"
                  to={cta.url}
                  label={cta.label}
                ></Link>
              )}
            ></Card.Link>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

ActionTile.defaultProps = {
  title: "",
  description: "",
  theme: "business",
  tag: "",
  type: "regular",
  layout: "",
};

ActionTile.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  theme: PropTypes.oneOf(["business", "individual", "providers","neutral"]), 
  tag: PropTypes.string,
  type: PropTypes.oneOf(["article", "service","chapter", "regular", "labor_education_tiles_item", "saudi_labor_system_tiles_item"]), 
  withLink: PropTypes.bool,
  cta: PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string,
  }), 
  layout: PropTypes.string,
};

export default ActionTile;
