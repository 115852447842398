import React, { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import Icon from "../Icon/Icon";
import { MainContext } from "../contexts/context";

import "./Quote.scss";

const Quote = ({ quotes }) => {
  const { theme } = useContext(MainContext);
  const [quoteData, setQuoteData] = useState(quotes);
  const [current, setCurrent] = useState(0);
  const [quote, getQuote] = useState(quoteData[current]);

  useEffect(() => {
    setQuoteData(quotes);

    getQuote(quoteData[current]);
  }, [theme]);

  useEffect(() => getQuote(quoteData[current]), [current, quote, quoteData]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleNextQuote();
    }, 4000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [current]);

  const handleNextQuote = () =>
    current === quoteData.length - 1
      ? setCurrent(0)
      : setCurrent((prev) => prev + 1);

  const handlePrevQuote = () =>
    current === 0
      ? setCurrent(quoteData.length - 1)
      : setCurrent((prev) => prev - 1);

  const handleDotPicksQuote = (index) => setCurrent(Number(index));

  return (
      <div className="quote-container">
        <div className="quote-container__content">
          {quote.author && (
            <Icon
              className="quote-container__icon"
              name="quote"
              viewBox="0 0 55 38"
              height="38"
              width="55"
            />
          )}
          <p className="quote-container__text">{quote.quote}</p>
          {quote.author && (
            <div className="quote-container__user-details">
              <img src={quote.author.image} alt="User profile" className="quote-container__user-img" />
              <div className="quote-container__user-details--content">
                <span className="quote-container__user-details--username">
                  {quote.author.name}
                </span>
                <span className="quote-container__user-details--job">
                  {quote.author.profession}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="quote-container__actions">
          <div className="quote-container__buttons">
            <button
              data-testid="prev-button-test"
              disabled={current === 0}
              onClick={handlePrevQuote}
              type="button"
              className={classNames({
                "quote-container__buttons--button": true,
                "quote-container__buttons__active-button": current !== 0,
              })}
            >
              <Icon name="arrow-left" />
            </button>
            <button
              data-testid="next-button-test"
              disabled={current === quoteData.length - 1}
              onClick={handleNextQuote}
              type="button"
              className={classNames({
                "quote-container__buttons--button": true,
                "quote-container__buttons__active-button":
                  current !== quoteData.length - 1,
              })}
            >
              <Icon name="arrow-right" />
            </button>
          </div>
          <div className="quote-container__dots">
            {quoteData.map((quoteData, index) => (
              <button
                key={index}
                onClick={() => handleDotPicksQuote(index)}
                tabIndex={0}
                className={classNames({
                  "quote-container__dots--dot": true,
                  "quote-container__dots__active-dot": index === current,
                })}
              ></button>
            ))}
          </div>
        </div>
      </div>
  );
};

Quote.defaultProps = {
  quotes: [],
};

Quote.propTypes = {
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string.isRequired,
      author: PropTypes.shape({
        name: PropTypes.string.isRequired,
        profession: PropTypes.string,
        image: PropTypes.string
      })
    })
  )
};

export default Quote;
