import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./HeroSection.scss";
import Breadcrumbs from "../../../../../Breadcrumbs/Breadcrumbs";
import Link from "../../../../../Link/Link";
import transformHeroTitle from "../../../transformNameToURL";
import { findLast, first } from "lodash";
import { MainContext } from "../../../../../contexts/context";
import classNames from "classnames";
import PropTypes from 'prop-types';

const HeroSection = ({ data, breadcrumbs, saudiLaborSystem }) => {
  const { theme } = React.useContext(MainContext);
  if (!data) {
    return null;
  }

  return (
    <div className={classNames({
      "hero-section": true,
      "hero-section--rtl": theme !== "english",
    })} 
    data-testid="hero-section">
    <Container  >
      <Row>
        <Col lg={6} className="ml-auto">
        <Breadcrumbs theme="light" Breadcrumbs={breadcrumbs}>
        {saudiLaborSystem ? ( 
              <Link
                to={"/labor-law"}
                label={theme === "english" ? "Labor Law" : "نظام العمل"}
              />
            ) : (
              breadcrumbs?.slice(1, -1).map((item, index) => (
                <Link
                  key={index}
                  to={item?.url}
                  label={item?.title}
                />
              ))
            )  
            }
          {breadcrumbs?.length > 0 && (
            <span>{breadcrumbs[breadcrumbs.length - 1]?.title }</span>
          )}
            </Breadcrumbs>
          <div className="hero-section__headline">{data?.headline}</div>
          <div className="journey-article-hero__text ">{data?.txt}</div>
        </Col>
      </Row>
    </Container>
    </div>
  );
};

HeroSection.propTypes = {
  data: PropTypes.shape({
    headline: PropTypes.string,  
    txt: PropTypes.string,      
  }), 
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,       
    title: PropTypes.string      
  })), 
  saudiLaborSystem: PropTypes.bool 
};

export default HeroSection;
