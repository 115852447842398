import React, { useState, useEffect } from "react";
import Link from "../../Link/Link";
import SLAFilters from "./SLAFilters.tsx";
import { useTranslation } from "react-i18next";
import { Accordion } from "react-bootstrap";
import Pager from "../../Pager/Pager.tsx";
import { isEmpty, toInteger } from "lodash";

const SLATable = ({
  data,
  activeFilter,
  generalTabData,
  onFilterChange,
  activeTab,
}: Props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState("10");
  const [dataPage, setDataPage] = useState<Array<any>>([]);
  const { t } = useTranslation("translation", { keyPrefix: "sla" });
  const [isGeneral, setIsGeneral] = useState(true);

  useEffect(() => {
    if (activeFilter === "general" || activeFilter?.label === "general") {
      setIsGeneral(true);
    } else {
      setIsGeneral(false);
    }
  }, [activeFilter]);

  useEffect(() => {
    isGeneral ? splitArrayOnPages(generalTabData) : splitArrayOnPages(data);
  }, [currentPage, itemsPerPage, isGeneral]);

  const splitArrayOnPages = (array: Array<any>) => {
    const startPoint = currentPage * toInteger(itemsPerPage);
    const endPoint = startPoint + toInteger(itemsPerPage);
    const resultArray = array.slice(startPoint, endPoint);
    setDataPage(resultArray);
  };

  const onItemsOnPageChange = (count: number) => {
    setItemsPerPage(`${count}`);
    setCurrentPage(0);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderActionContent = (row: any) => {
    // Destructure to easily check properties
    const { number, mail, url, zendesk, label } = row;

    if (number) {
      return <Link underlined={true} to={`tel:+966${number}`} label={number} />;
    } else if (mail) {
      return <Link underlined={true} to={`mailto:${mail}`} label={mail} />;
    } else if (url && label === "whatsApp") {
      return (
        <Link
          underlined={true}
          to={url}
          target="_blank"
          iconSize="small"
          label={t("startChat")}
          icon={true}
        />
      );
    } else if (url && label === "signLanguageSupport") {
      return (
        <Link
          to={url}
          label={t("startVideoCall")}
          target="_blank"
          iconSize="small"
          underlined={true}
          icon={true}
        />
      );
    }
  };
  const renderActionContentMobile = (row: any) => {
    // Destructure to easily check properties
    const { number, mail, url, zendesk, label } = row;

    if (number) {
      return <Link underlined={true} to={`tel:+966${number}`} label={number} />;
    } else if (mail) {
      return <Link underlined={true} to={`mailto:${mail}`} label={mail} />;
    } else if (url && label === "whatsApp") {
      return (
        <Link
          to={url}
          label={t("startChat")}
          underlined={false}
          icon={true}
          iconPosition="before"
          className="sla-table__accordion-action"
        />
      );
    } else if (url && label === "signLanguageSupport") {
      return (
        <Link
          to={url}
          label={t("startVideoCall")}
          target="_blank"
          underlined={false}
          icon={true}
          iconPosition="before"
          className="sla-table__accordion-action"
        />
      );
    }
  };

  return (
    <div className="sla-table">
      <div className="sla-table__filters sla-table__filters--mobile">
        <SLAFilters onFilterChange={onFilterChange} activeTab={activeTab} />
      </div>
      <ul className="sla-table__list">
        <div className="sla-table__filters sla-table__filters--desktop">
          <SLAFilters onFilterChange={onFilterChange} activeTab={activeTab} />
        </div>
        <li className="sla-table__list-item sla-table__list-item--header">
          <div className="sla-table__list-item-column sla-table__list-item-service">
            {isGeneral ? t("channel") : t("service")}
          </div>
          <div className="sla-table__list-item-column sla-table__list-item-time">
            {t("responseTime")}
          </div>
          <div className="sla-table__list-item-column sla-table__list-item-availability">
            {t("serviceAvailability")}
          </div>
          <div className="sla-table__list-item-column sla-table__list-item-customer-care">
            {t("inquiryResponseTime")}
          </div>
          <div className="sla-table__list-item-column sla-table__list-item-complain-resolution">
            {t("complainTechIssue")}
          </div>
          <div className="sla-table__list-item-column sla-table__list-item-action">
            {t("action")}
          </div>
        </li>
        {!isGeneral &&
          data &&
          dataPage.map((row, index) => {
            return (
              <li key={index} className="sla-table__list-item">
                <div className="sla-table__list-item-column sla-table__list-item-service">
                  {t(row.label)}
                </div>
                <div className="sla-table__list-item-column sla-table__list-item-time">
                  {t("instant")}
                </div>
                <div className="sla-table__list-item-column sla-table__list-item-availability">
                  24/7
                </div>
                <div className="sla-table__list-item-column sla-table__list-item-customer-care">
                  {t("minutes")}
                </div>
                <div className="sla-table__list-item-column sla-table__list-item-complain-resolution">
                  {t("businessDays")}
                </div>
                <div className="sla-table__list-item-column sla-table__list-item-action">
                  <Link
                    to={row.url}
                    label={t("overview")}
                    underlined={true}
                    disabled={isEmpty(row.url)}
                  />
                </div>
              </li>
            );
          })}
        {isGeneral &&
          generalTabData &&
          dataPage.map((row, index) => {
            return (
              <li key={index} className="sla-table__list-item">
                <div className="sla-table__list-item-column sla-table__list-item-service">
                  {t(row.label)}
                </div>
                <div className="sla-table__list-item-column sla-table__list-item-time">
                  {t("instant")}
                </div>
                <div className="sla-table__list-item-column sla-table__list-item-availability">
                  24/7
                </div>
                <div className="sla-table__list-item-column sla-table__list-item-customer-care">
                  {row.inquiryResponseTime ? t("instant") : t("minutes")}
                </div>
                <div className="sla-table__list-item-column sla-table__list-item-complain-resolution">
                  {t("businessDays")}
                </div>
                <div className="sla-table__list-item-column sla-table__list-item-action">
                  {renderActionContent(row)}
                </div>
              </li>
            );
          })}
        {!isGeneral && (
          <Pager
            currentPage={currentPage}
            totalItems={`${data.length}`}
            totalPages={
              data.length % toInteger(itemsPerPage) === 0
                ? toInteger(data.length / toInteger(itemsPerPage))
                : toInteger(data.length / toInteger(itemsPerPage)) + 1
            }
            itemsPerPage={itemsPerPage}
            onItemsChange={onItemsOnPageChange}
            onPageChange={onPageChange}
          />
        )}
      </ul>
      <div className="sla-table__accordion-list">
        <Accordion defaultActiveKey="0">
          {!isGeneral &&
            data &&
            dataPage.map((row, index) => {
              return (
                <Accordion.Item eventKey={`${index}`} key={index}>
                  <Accordion.Header>{t(row.label)}</Accordion.Header>
                  <Accordion.Body>
                    <div className="sla-table__accordion-body-item">
                      <p className="sla-table__accordion-body-item-label">
                        {t("timeToComplete")}
                      </p>
                      <p className="sla-table__accordion-body-item-value">
                        {t("instant")}
                      </p>
                    </div>
                    <div className="sla-table__accordion-body-item">
                      <p className="sla-table__accordion-body-item-label">
                        {t("serviceAvailability")}
                      </p>
                      <p className="sla-table__accordion-body-item-value">
                        24/7
                      </p>
                    </div>
                    <div className="sla-table__accordion-body-item">
                      <p className="sla-table__accordion-body-item-label">
                        {t("customerCare")}
                      </p>
                      <p className="sla-table__accordion-body-item-value">
                        {t("minutes")}
                      </p>
                    </div>
                    <div className="sla-table__accordion-body-item">
                      <p className="sla-table__accordion-body-item-label">
                        {t("complain")}
                      </p>
                      <p className="sla-table__accordion-body-item-value">
                        {t("businessDays")}
                      </p>
                    </div>
                    <div className="sla-table__accordion-body-item">
                      <Link
                        to={row.url}
                        label={t("overview")}
                        underlined={false}
                        disabled={isEmpty(row.url)}
                        className={`${
                          isEmpty(row.url)
                            ? "sla-table__accordion-action--disabled"
                            : "sla-table__accordion-action"
                        }`}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          {isGeneral &&
            data &&
            dataPage.map((row, index) => {
              return (
                <Accordion.Item eventKey={`${index}`} key={index}>
                  <Accordion.Header>{t(row.label)}</Accordion.Header>
                  <Accordion.Body>
                    <div className="sla-table__accordion-body-item">
                      <p className="sla-table__accordion-body-item-label">
                        {t("timeToComplete")}
                      </p>
                      <p className="sla-table__accordion-body-item-value">
                        {t("instant")}
                      </p>
                    </div>
                    <div className="sla-table__accordion-body-item">
                      <p className="sla-table__accordion-body-item-label">
                        {t("serviceAvailability")}
                      </p>
                      <p className="sla-table__accordion-body-item-value">
                        24/7
                      </p>
                    </div>
                    <div className="sla-table__accordion-body-item">
                      <p className="sla-table__accordion-body-item-label">
                        {t("customerCare")}
                      </p>
                      <p className="sla-table__accordion-body-item-value">
                        {t("minutes")}
                      </p>
                    </div>
                    <div className="sla-table__accordion-body-item">
                      <p className="sla-table__accordion-body-item-label">
                        {t("complain")}
                      </p>
                      <p className="sla-table__accordion-body-item-value">
                        {t("businessDays")}
                      </p>
                    </div>
                    <div className="sla-table__accordion-body-item">
                      {renderActionContentMobile(row)}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
        </Accordion>
        {!isGeneral && (
          <Pager
            currentPage={currentPage}
            totalItems={`${data.length}`}
            totalPages={
              data.length % toInteger(itemsPerPage) === 0
                ? toInteger(data.length / toInteger(itemsPerPage))
                : toInteger(data.length / toInteger(itemsPerPage)) + 1
            }
            itemsPerPage={itemsPerPage}
            onItemsChange={onItemsOnPageChange}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
};

type Props = {
  data: Array<object>;
  generalTabData: Array<object>;
  activeFilter?: any;
  onFilterChange?: (filterType: string) => void;
  activeTab?: any;
};

SLATable.defaultProps = {
  data: [],
  generalTabData: [],
};

export default SLATable;
