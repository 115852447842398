import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { MainContext } from "../contexts/context";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { useLocation } from "react-router-dom";
import AIAssistantEntryLink from "../pages/AIAssistant/components/AIAssistantEntryLink/AIAssistantEntryLink.tsx";

import "./Hero.scss";
import HeroSearchInput from "../Search/HeroSearchInput/HeroSearchInput.tsx";

const Hero = ({ data }) => {
  const [heroData, setHeroData] = useState({});
  const [isHomepage, setIsHomepage] = useState(true);
  const contextValue = useContext(MainContext);
  const { hero, currentBreadcrumb } = contextValue;
  let location = useLocation();

  useEffect(() => {
    if (data?.field_headline?.value ) {
      setHeroData({
        mainTitle: data?.field_headline?.value,
        subTitle: data?.field_txt?.value,
      });
    }
    if (hero) {
      setHeroData(hero);
    }
  }, [hero, data]);

  const allowedPathnames = ["/en", "/ar", '/en/services', '/ar/services'];

  useEffect(() => {
    if (allowedPathnames.includes(location.pathname)) {
      setIsHomepage(true);
    } else {
      setIsHomepage(false);
    }
  }, [location]);

  return (
    <div
      className={`hero ${isHomepage ? "" : "hero--simple"}`}
      data-testid="hero-test"
    >
      <Container>
        <Row>
          <Col lg="7">
            {!isHomepage && (
              <Breadcrumbs theme="light">
                <span>{currentBreadcrumb}</span>
              </Breadcrumbs>
            )}
            {data?.field_headline?.value ? (
              <h1 className="hero__main-title">
                {data?.field_headline?.value}
              </h1>
            ) : (
              <div
                className="hero__main-title"
                dangerouslySetInnerHTML={{
                  __html: isHomepage ? heroData.mainTitle : currentBreadcrumb,
                }}
              />
            )}
          </Col>
        </Row>
        {isHomepage ? (
          <Row>
            <Col lg="7">
              <div
                className="hero__sub-title"
                dangerouslySetInnerHTML={{ __html: heroData.subTitle }}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg="7">
              <div
                className="hero__sub-title"
              />
              {data?.field_txt?.value}
            </Col>
          </Row>
        )}
        {isHomepage && (
          <Row>
            <Col lg="6">
              <HeroSearchInput id="main-hero-search-input-id" isSaudiLabor={false} />
            </Col>
            <Col lg="6">
              <AIAssistantEntryLink />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

Hero.propTypes = {
  data: PropTypes.shape({
    field_headline: PropTypes.shape({
      value: PropTypes.string,
    }),
    field_txt: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
};

Hero.defaultProps = {
  data: {
    field_headline: {
      value: "",
    },
    field_txt: {
      value: "",
    },
  },
};

export default Hero;
