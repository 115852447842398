import styled from 'styled-components';
import { respondAbove } from "@takamol/qiwa-design-system/styles/mediaQueries";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const Container = styled.div`
  max-width: 100%;
  padding: 0 12px;

  @media ${respondAbove.lg} {
    max-width: 890px;
    padding: 0;
  }
`

const AnimationContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .ai-assistant-page__animation-background {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    transform: translateX(-20%) translateY(-25%);
    z-index: 1;
  }

  video {
    position: relative;
    z-index: 2;
    top: 0;
    inset-inline-start: 0;
    height: 210px;
    width: 210px;
  }
`

const AnimationForeground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  z-index: 5;
  background-color: transparent;
`

const PageTextContainer = styled.div`
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${respondAbove.lg} {
    margin-bottom: 56px; 
  }
`

const PageTitle = styled.h5`
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 12px;
  background: linear-gradient(298deg, #0060FF 29.9%, #0A0E14 54.23%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const PageDescription = styled.div`
  margin: 0;
  text-align: center;
  max-width: 668px;
  padding: 0 8px;

  p {
    margin: 0;
    color: ${props => props.theme.colors.grayscale_600}
  }

  @media ${respondAbove.lg} {
    padding: 0;
  }
`

const QuestionsContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 48px;
  flex-wrap: wrap;

  @media ${respondAbove.lg} {
    flex-wrap: nowrap;
    margin-bottom: 114px;
  }
`

const QuestionButton = styled.button`
  background-color: white;
  border: none;
  border-radius: 16px;
  border: 1px solid #F2F2F2;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.08);
  padding: 20px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  transition: background-color 0.1s ease-in;

  &:hover {
    background-color: rgb(240, 247, 255);
    transition: background-color 0.1s ease-in;
  }

  &:focus-visible {
    z-index: 1;
    outline: rgb(0, 0, 0) solid 3px;
    outline-offset: -3px;
    box-shadow: rgb(243, 195, 84) 0px 0px 0px 3px;
    transition: outline 0.05s ease-in;
  }

  @media ${respondAbove.lg} {
    width: calc((100% - 24px) / 3);
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;

  @media ${respondAbove.lg} {
    margin-bottom: 56px;
  }
`

const FooterText = styled.p`
  margin: 0 0 24px;
  text-align: center;
  color: #4B515A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const Styled = {
  PageWrapper,
  Container,
  AnimationContainer,
  AnimationForeground,
  PageTextContainer,
  PageTitle,
  PageDescription,
  QuestionsContainer,
  QuestionButton,
  InputContainer,
  FooterText,
};
