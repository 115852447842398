import {
  VideoPlayer,
  Box,
  Modal,
  Text,
  Button as QiwaButton,
  Image,
  Divider,
} from "@takamol/qiwa-design-system/components";
import PropTypes from "prop-types";
import { useEffect, useState, useRef } from "react";
import { map, toNumber, findLast } from "lodash";
import Icon from "../../../../../Icon/Icon";
import "./VideoArticleInside.scss";
import Link from "../../../../../Link/Link";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { videoDataShape } from "./propShapes";

const VideoArticleInside = (videoData) => {

  const data = videoData?.data;
  const { t } = useTranslation("translation", { keyPrefix: "videoBanner" });
  const [showModal, setShowModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const Caret = () => (
    <Icon
      name="play"
      viewBox="-4 -4 32 32"
      width="10"
      height="10"
      className="video-inside__player-icon"
      fill="#FFFFFF"
    />
  );

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleContactClick = () => {
    const contactUrl = `/${localStorage.getItem("i18nextLng")}${
      data?.metaInformation?.contact_us?.url
    }`;
    if (contactUrl) {
      window.open(contactUrl, "_blank");
    }
    handleClose();
  };

  const convertedData = map(data?.chapters, (item) => ({
    ...item,
    seconds: toNumber(item?.time),
  }));

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const findChapter = () => {
    const matchedChapter = findLast(convertedData, (chapter) => {
      return chapter.seconds <= data.startTime;
    });

    if (matchedChapter) {
      return {
        title: matchedChapter.title,
        time: formatTime(matchedChapter.seconds),
      };
    }
    return null;
  };

  const chapterInfo = findChapter();
  return (
    <div className="video-inside" data-testid="video-inside">
      <Container fluid className="video-inside__container" data-testid="video-inside__container">
        <Row className="video-inside__row">
          <Col lg={6} md={6}  className="video-inside__left-col">
            <button className="video-inside__image-container" onClick={handleOpen} data-testid="video-image-container" >
              <Image
                altText="Meet new Qiwa Platform!"
                aspectRatio="16 / 9"
                src={data?.poster}
                borderRadius={4}
                overlay="light"
              />
              {width < 768 && (
              <div className="video-inside__watch-video-button">
                <QiwaButton
                  onClick={handleOpen}
                  variant="business_ghost"
                  iconComponent={Caret}
                  iconPlacement="start"
                  data-testid="watch-video-button"
                >
                  {data?.metaInformation?.watch}
                </QiwaButton>
              </div>)}
            </button>
          </Col>
          <Col lg={6} md={6} className="video-inside__right-col">
            <div className="video-inside__right-col__title"  data-testid="video-title">
              {chapterInfo?.title}
            </div>
            <div className="video-inside__right-col__duration" data-testid="video-duration">
              {data?.metaInformation?.duration}: {data?.duration}
            </div>
            {width >= 768 && (
            <div className="video-inside__watch-video-button">
              <QiwaButton
                onClick={handleOpen}
                variant="business_ghost"
                iconComponent={Caret}
                iconPlacement="start"
                size="medium"
                data-testid="watch-video-button"
              >
                {data?.metaInformation?.watch}
              </QiwaButton>
            </div>)}
          </Col>
        </Row>
      </Container>

      <Modal
        hasCloseButton={true}
        isOpened={showModal}
        handleClose={handleClose}
        desktopWidth="extraWide"
        data-testid="video-modal"
      >
        <Modal.Body>
          <Box gap={24} className="video-inside__video-group">
            <Text
              pe={8}
              variant={["heading-xxs", "heading-xs"]}
              weight="semibold"
              data-testid="modal-video-title"
            >
              {data?.title}
            </Text>
            <Text variant="body-l" data-testid="modal-video-subtitle">{data?.subtitle}</Text>
            <VideoPlayer
              withAutoplay={true}
              startTime={data?.startTime}
              chapters={convertedData}
              chaptersTitle={data?.metaInformation?.table_of_contents}
              videoSrc={data?.videoSrc}
              poster={data?.poster}
              size="large"
              altPosterDescription="Video poster"
              chaptersPosition="side"
              chaptersMaxHeight={320}
              data-testid="mock-video-player"
            />
          </Box>
        </Modal.Body>
        <Modal.Footer>
          {width >= 769 && (
            <div className="video-inside__footer">
              <Divider />
              <Text className="video-inside__help">
                {data?.metaInformation?.help}{" "}
                {
                  <Link to={data?.metaInformation?.contact_us?.url}>
                    {data?.metaInformation?.contact_us?.title}
                  </Link>
                }
              </Text>
            </div>
          )}
          {width < 769 && (
            <div className="video-inside__modal-buttons">
              <QiwaButton onClick={handleClose}>{t("closeModal")}</QiwaButton>
              <QiwaButton
                variant="individuals_ghost"
                onClick={handleContactClick}
                data-testid="contact-button"
              >
                {data?.metaInformation?.help}{" "}
                {data?.metaInformation?.contact_us?.title}
              </QiwaButton>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};


VideoArticleInside.propTypes = {
  videoData: PropTypes.shape(videoDataShape),
};

export default VideoArticleInside;
