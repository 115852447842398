import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import OrderComponent from "../../../Shared/Order/Order";
import Text from "../../../Shared/Text/Text";
import LearnMore from "../../../Shared/LearnMore/LearnMore";
import Information from "../../../Shared/Information/Information";
import "../../Article.scss";
import "../Common.scss";
import { MainContext } from "../../../../../contexts/context";
import classnames from "classnames";
import { isEmpty } from "lodash";
import ButtonComponent from "../../../Shared/Button/Button";
import FileDownloadCard from "../../../Shared/Download/Download";
import VideoArticleInside from "../Videos/VideoArticleInside";

const SectionOne = ({ data, order,className }) => {
  const { theme } = useContext(MainContext);
  if (isEmpty(data)) {
    return null;
  }
  return (
    <div className={classnames({
      article: true,
      "article--rtl": theme !== "english",
    })} data-testid="tags-section">
      <Container >
        <Row>
          {data?.type === "1" && (
            <Col xs={12} md={1} lg={1}>
              <OrderComponent order={data?.number_choose} lineIsNeed={true} />
              <div
                className={classnames({
                  "article__line": true,
                  "article__line--rtl": theme !== "english",
                })}
              ></div>
            </Col>
          )}
          <Col xs={12} md={12} lg={11}>
            <div className="article__title">
            {data?.type !== "videoplayer"  && <Text text={data?.title} copyText={true}/>}
            </div>
            <div className="article__content">
            <Text text={data?.content || data} copyText={true}/>
            </div>
            {data?.type === "videoplayer" && <VideoArticleInside data={data} />}
            {data?.tagsInside?.map((item, index) => {
              if (item.type === "button") {
                return (
                  <ButtonComponent
                    key={`key-${index}`}
                    button_text={item?.button_text}
                    button_link={item?.button_link}
                    className={className || "Primary"}
                  />
                );
              }
              if (item.type === "file") {
                return <FileDownloadCard key={`key-${index}`} data={item?.button_text} />;
              }
              if (item.type === "description") {
                return (
                  <Text key={`key-${index}`} text={item?.tags_inside_description} copyText={true}/>
                );
              }
              if (item.type === "learn_more_wrapper") {
                const learnMoreData = {
                  questions: item?.learn_more_paragraphs.flatMap((paragraph) =>
                    paragraph.map((p) => ({
                      text: p?.learn_more_inside_title,
                      link: p?.learn_more_inside_link,
                    }))
                  ),
                };
                return (
                  <LearnMore
                    key={`key-${index}`}
                    data={learnMoreData}
                    title={item?.learn_more_wrapper_title}
                  />
                );
              }
              if (item.type === "information") {
                const informationData = {
                  header: item?.information_title,
                  questions: [
                    {
                      text: item?.information_text,
                      link: item?.information_link,
                    },
                  ],
                };
                return <Information key={`key-${index}`} data={informationData} />;
              }
              return null;
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

SectionOne.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string, 
    number_choose: PropTypes.string, 
    title: PropTypes.string, 
    content: PropTypes.string,
    tagsInside: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        button_text: PropTypes.string, 
        button_link: PropTypes.string,
        tags_inside_description: PropTypes.string,
        learn_more_paragraphs: PropTypes.arrayOf(
          PropTypes.arrayOf(
            PropTypes.shape({
              learn_more_inside_title: PropTypes.string,
              learn_more_inside_link: PropTypes.string,
            })
          )
        ),
        information_title: PropTypes.string, 
        information_text: PropTypes.string,
        information_link: PropTypes.string, 
      })
    ),
  }), 
  order: PropTypes.string, 
  className: PropTypes.string,
};

export default SectionOne;
