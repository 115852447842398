import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";
import { MainContext } from "./components/contexts/context";
import axios from "axios";
import GlobalStyles from "@takamol/qiwa-design-system/styles/global";
import { theme as qiwaDesignTheme } from "@takamol/qiwa-design-system/styles/theme";
import { ThemeProvider } from "styled-components";
import useScrollToTop from "./hooks/useScrollToTop.jsx";
import CookieConsent from "./cookies/cookies.tsx";
import LocalizedApp from "./LocalizedApp.js";
import { checkLanguageInUrl } from "./utils/checkLanguageInUrl.js";
import { PageLoader } from "./components/Loader/PageLoader.tsx";
import TitleSetter from "./TitleSetter.js";
import AIAssistant from "./components/pages/AIAssistant/AIAssistant.tsx";
import AIAssistantAnswers from "./components/pages/AIAssistant/AIAssistantAnswers.tsx";

export const fetchData = async (setDataIsLoading) => {
  try {
    const defaultLanguage = ["en", "ar"].includes(
      localStorage.getItem("i18nextLng")
    )
      ? localStorage.getItem("i18nextLng")
      : "ar";
    const urlLanguage = checkLanguageInUrl(window.location.pathname);
    const language =
      defaultLanguage === urlLanguage ? defaultLanguage : urlLanguage;
    setDataIsLoading(true);
    const res = await axios.get(
      process.env.REACT_APP_FULL_URL + "/api/response_v4",
      { params: { language: language === "ar" ? "arabic" : undefined } }
    );
    if (res && res.status === 200 && res.data[0]) {
      setDataIsLoading(false);
      return res.data[0];
    } else {
      setDataIsLoading(false);
      return {};
    }
  } catch (error) {
    setDataIsLoading(false);
    return {
      error: error?.response?.status,
    };
  }
};

function App() {
  const [theme, setTheme] = useState("english");
  const [currentBreadcrumb, setCurrentBreadcrumb] = useState("");
  const [contextData, setContextData] = useState({});
  const [headerData, setHeaderData] = useState({ variant: "dark" });
  const [footerData, setFooterData] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [searchUpdateValue, setSearchUpdateValue] = useState(1);
  const [aiUpdateValue, setAiUpdateValue] = useState(null);


  const { i18n } = useTranslation();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("i18nextLng");
    const defaultLanguage = ["en", "ar"].includes(storedLanguage)
      ? storedLanguage
      : "ar";
    const urlLanguage = checkLanguageInUrl(window.location.pathname);
    const language =
      defaultLanguage === urlLanguage ? defaultLanguage : urlLanguage;
    i18n.changeLanguage(language);

    if (language) {
      if (language === "en") {
        setTheme("english");
        document.body.dir = "";
        document.body.lang = "en";
      } else {
        setTheme("arabic");
        document.body.dir = "rtl";
        document.body.lang = "ar";
      }
    }
  }, [i18n, window.location.pathname]);

  useEffect(() => {
    document.body.className = theme;
    fetchData(setDataIsLoading).then((res) => {
      if (res) {
        setContextData(res);
        setFooterData(res.footerData);
      }
    });
  }, [theme]);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (width < 768 && !isMobile) {
      setIsMobile(true);
      setIsTablet(false);
    } else if (width >= 768 && width <= 992 && !isTablet) {
      setIsMobile(false);
      setIsTablet(true);
    } else if (width > 992) {
      setIsMobile(false);
      setIsTablet(false);
    }
  }, [width, isMobile, isTablet]);

  const data = {
    theme,
    isMobile,
    isTablet,
    setTheme,
    currentBreadcrumb,
    setCurrentBreadcrumb,
    setHeaderData,
    showMobileSearch,
    setShowMobileSearch,
    footerData,
    headerData,
    searchUpdateValue,
    setSearchUpdateValue,
    aiUpdateValue,
    setAiUpdateValue,
    ...contextData,
  };
  return (
    <ThemeProvider theme={qiwaDesignTheme}>
      <MainContext.Provider value={data}>
        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript>
          {process.env.REACT_APP_NODE_ENV !== 'development' && <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GTM_ID}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
            title="Google Tag Manager noscript"
          ></iframe>}
        </noscript>
        {/* <!-- End Google Tag Manager (noscript) --> */}
        <Helmet>
          {/* <!-- Data Layer Declaration --> */}
          <script>{`window.dataLayer = window.dataLayer || [];`}</script>
          {/* <!-- End Data Layer Declaration --> */}
          {/* <!-- Google Tag Manager --> */}
          {process.env.REACT_APP_NODE_ENV !== 'development' && <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.REACT_APP_GTM_ID}');`}
          </script>}
          {/* <!-- End Google Tag Manager --> */}
        </Helmet>
        {dataIsLoading && <PageLoader />}
        <GlobalStyles />
        <Router>
          <TitleSetter />
          <ScrollComponent />
          <Routes>
            {["en", "ar"].map((value) => (    // General pages
              <Route
                key={value}
                path={`/${value.toLowerCase()}/*`}
                element={<LocalizedApp />}
              />
            ))}
            <Route path="*" element={<Navigate to={`/${i18n.language}`} />} />
            {["en", "ar"].map((value) => (    // AI assistant pages without header and footer
              <Route
                key={value}
                path={`/${value.toLowerCase()}/ai-assistant`}
                element={<AIAssistant />}
              ></Route>
            ))}
            {["en", "ar"].map((value) => (    // AI assistant answers pages without header and footer
              <Route
                key={value}
                path={`/${value.toLowerCase()}/ai-assistant-answers`}
                element={<AIAssistantAnswers />}
              ></Route>
            ))}
          </Routes>
          <CookieConsent />
        </Router>
      </MainContext.Provider>
    </ThemeProvider>
  );
}
export default App;

function ScrollComponent() {
  useScrollToTop();

  return null;
}
