import React from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import IconCard from "../IconCard/IconCard";

import "./IconCardsGroup.scss";

const IconCardsGroup = ({ variant, groupData }) => {
  return (
    <div
      className={`icon-cards-group ${variant ? "icon-cards-group--dark" : ""}`}
      data-testid="icon-cards-group-test"
    >
      <Container>
        <Row>
          {groupData.title && (
            <h3 dangerouslySetInnerHTML={{ __html: groupData.title }} />
          )}
            {groupData.subtitle && (
              <div
                className="icon-cards-group__subtitle"
                dangerouslySetInnerHTML={{ __html: groupData.subtitle }}
              />
            )}
        </Row>
        <Row xs="1" md="2" lg={groupData?.items?.length >= 6 ? "6" : groupData?.items?.length} className="icon-cards-group__cards-row">
          {groupData.items &&
            groupData.items.length &&
            groupData.items.map((card, index) => {
              return (
                <Col key={index}>
                  <IconCard index={index} type={groupData?.type === "qiwa_in_numbers" ? "statistics" : "whyUsing"} icon={card.icon} description={card.description || card.title} number={card.number} />
                </Col>
              );
            })}
        </Row>
      </Container>
    </div>
  );
};

IconCardsGroup.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.string]), 
  groupData: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string, 
        description: PropTypes.string, 
        title: PropTypes.string, 
        number: PropTypes.number, 
      })
    ),
  }),
};

IconCardsGroup.defaultProps = {
  variant: undefined,
  groupData: {
    title: "",
    subtitle: "",
    cards: [],
  },
};

export default IconCardsGroup;
