import PropTypes from "prop-types";

export const contactUsShape = PropTypes.shape({
  url: PropTypes.string,
  title: PropTypes.string,
});

export const metaInformationShape = PropTypes.shape({
  watch: PropTypes.string,
  help: PropTypes.string,
  table_of_contents: PropTypes.string,
  contact_us: contactUsShape,
  duration: PropTypes.string,
});

export const chapterShape = PropTypes.shape({
  title: PropTypes.string,
  time: PropTypes.string,
});

export const videoDataShape = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  poster: PropTypes.string,
  videoSrc: PropTypes.string,
  startTime: PropTypes.number,
  duration: PropTypes.string,
  chapters: PropTypes.arrayOf(chapterShape),
  metaInformation: metaInformationShape,
};
