import styled from "styled-components";

const ActionsWrapper = styled.div`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  width: 100%;
`

const SingleActionWrapper = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`

const FeedbackWrapper = styled.div`
  margin: 0;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`

export const Styled = {
  ActionsWrapper,
  SingleActionWrapper,
  FeedbackWrapper,
}