import React, { useContext } from "react";
import "./RelatedTopics.scss";
import PropTypes from "prop-types";
import Icon from "../../../../Icon/Icon";
import { MainContext } from "../../../../contexts/context";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import classNames from "classnames";

const RelatedTopics = ({ data }) => {
  const { theme } = useContext(MainContext);
  const { t } = useTranslation("translation", {
    keyPrefix: "article",
  });

  if (isEmpty(data)) {
    return null;
  }

  const isEnglish = theme === "english";
  const relatedTopicsClass = classNames("related-topics", {
    "related-topics--ltr": isEnglish,
    "related-topics--rtl": !isEnglish,
  });

  const topics = Array.isArray(data[0]) ? data.flat() : data;
  const isSingleItem = topics.length === 1;

  return (
    <div className={relatedTopicsClass}>
      <div className="related-topics__heading">{t("relatedTopics")}</div>
      <div
        className={`related-topics__list${!isSingleItem ? "__multiple" : ""}`}
      >
        {topics.map((topic, index) => (
          <a
            href={`/${localStorage.getItem("i18nextLng")}${
              topic.link || topic.url
            }`}
            className="related-topics__actione-tile"
            key={`${index}-${topic.title}`}
          >
            <div className="related-topics__actione-tile-title">
              {topic.title}
            </div>
            <div className="related-topics__actione-tile-container">
              <div className="related-topics__icon">
                <Icon name="arrow-right" color="black" />
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

RelatedTopics.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string.isRequired,
        url: PropTypes.string,
        link: PropTypes.string
      })
    ),
    PropTypes.array
  ])
};

export default RelatedTopics;
