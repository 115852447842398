const addQuestion = (msg: {author: string; msg: string; uuid?: string; isComplete?: boolean;}) => {
  const dialogStorage = localStorage.getItem('aiAssistantDialog');
  if (dialogStorage == null) {
    localStorage.setItem('aiAssistantDialog', JSON.stringify([
      {
        author: msg.author,
        msg: msg.msg,
        uuid: '',
        order: 1,
        isComplete: msg.isComplete
      }
    ]))
  } else {
    const dialogArray = JSON.parse(dialogStorage);
    dialogArray.push({
      author: msg.author,
      msg: msg.msg,
      uuid: msg.uuid ? msg.uuid : dialogArray[dialogArray.length - 1].uuid,
      order: dialogArray.length + 1,
      isComplete: msg.isComplete
    })
    localStorage.setItem('aiAssistantDialog', JSON.stringify(dialogArray));
  }
}

const addFirstQuestion = (msg: {author: string; msg: string; uuid?: string;}) => {
  localStorage.setItem('aiAssistantDialog', JSON.stringify([
    {
      author: msg.author,
      msg: msg.msg,
      uuid: '',
      order: 1
    }
  ]))
}

const modifyLastMsg = (msg: {author: string; msg: string; uuid?: string; order?: number, isComplete?: boolean;}) => {
  const dialogStorage = localStorage.getItem('aiAssistantDialog');
  if (dialogStorage === null || !msg) {
    return;
  } else {
    const dialogArray = JSON.parse(dialogStorage);
    const newDialogArray = [
      ...dialogArray.slice(0, dialogArray.length - 1),
      msg,
    ]
    localStorage.setItem('aiAssistantDialog', JSON.stringify(newDialogArray));
  } 
}

export const manageQuestionsStorage = {
  addQuestion,
  addFirstQuestion,
  modifyLastMsg
};