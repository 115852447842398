import React, { useContext } from "react";
import Link from "../../../../Link/Link";
import { Styled } from "./AIAssistantEntryLink.styles";
import Icon from "../../../../Icon/Icon";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../../../contexts/context";

type AIAssistantEntryLinkProps = {
  variant?: "homepage" | "article";
};

const AIAssistantEntryLink = ({
  variant = "homepage",
}: AIAssistantEntryLinkProps) => {
  const { theme } = useContext(MainContext);
  const { t } = useTranslation("translation", {
    keyPrefix: "aiAssistant",
  });

  return (
    <Link to="/ai-assistant">
      <Styled.LinkContentContainer
        data-testid="ai-assistant-entry-link-test-id"
        $variant={variant}
      >
        <Icon name="aiQiwa" height="40px" width="40px" viewBox="0 0 21 20" />
        <Styled.TextContainer>
          <Styled.TopText>
            {t("askQiwa")} <span>beta</span>{" "}
            {variant === "article" && (
              <Icon
                name={theme === "english" ? "chevron-right" : "chevron-left"}
              />
            )}
          </Styled.TopText>
          <Styled.BottonText>{t("qiwaVA")}</Styled.BottonText>
        </Styled.TextContainer>
      </Styled.LinkContentContainer>
    </Link>
  );
};

export default AIAssistantEntryLink;
