import React from "react";
import PropTypes from "prop-types";
import Link from "../Link/Link";
import Icon from "../Icon/Icon";
import { Container } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import phoneENG from "./img/phone-english.png";
import phoneAR from "./img/phone-arabic.png";
import laptopENG from "./img/laptop-english.png";
import laptopAR from "./img/laptop-arabic.png";
import controlENG from "./img/control-english.png";
import controlAR from "./img/control-arabic.png";

import "./BenefitsBanner.scss";

const BenefitsBanner = ({ signInLink }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "landing.benefits",
  });

  return (
    <div>
      <div className="benefits-banner-background">
        <Container className="benefits-banner">
          <div className="benefits-banner__main-wrapper">
            <div className="benefits-banner__title-box">
              <h2 className="benefits-banner__main-title">
                <Trans
                  i18nKey="title"
                  t={t}
                  components={[
                    <span
                      key="highlighted-text"
                      className="benefits-banner__blue-title"
                    ></span>,
                  ]}
                />
              </h2>
              <p className="benefits-banner__secondary-title">
                {t("description")}
              </p>
            </div>
            <div className="benefits-banner__article-wrapper">
              <div className="benefits-banner__image-box">
                <img
                  src={localStorage.getItem("i18nextLng") === "ar" ? phoneAR : phoneENG}
                  className="benefits-banner__article-img"
                  alt="Benefits for Emplyers"
                />
                <div className="benefits-banner__banner benefits-banner__banner__left">
                  <div className="benefits-banner__banner__icon-box">
                    <Icon
                      name="badge-document"
                      viewBox="0 0 19 19"
                      height="19"
                      width="19"
                    />
                  </div>
                  <div className="benefits-banner__banner__divider-main" />
                  <div className="benefits-banner__banner__divider-secondary" />
                </div>
              </div>
              <div className="benefits-banner__article-box">
                <h3 className="benefits-banner__article-title">
                  {t("first_section_business.title")}
                </h3>
                <ul className="benefits-banner__article-list">
                  <li>
                    <span>{t("first_section_business.item_1.title")}</span>
                    <p>{t("first_section_business.item_1.text")}</p>
                  </li>
                  <li>
                    <span>{t("first_section_business.item_2.title")}</span>
                    <p>{t("first_section_business.item_2.text")}</p>
                  </li>
                  <li>
                    <span>{t("first_section_business.item_3.title")}</span>
                    <p>{t("first_section_business.item_3.text")}</p>
                  </li>
                </ul>
                <Link
                  to={signInLink}
                  className="benefits-banner__article-button"
                >
                  {t("first_section_business.button_text")}
                </Link>
              </div>
            </div>
            <div className="benefits-banner__article-wrapper-left">
              <div className="benefits-banner__article-box">
                <h3 className="benefits-banner__article-title">
                  {t("second_section_business.title")}
                </h3>
                <ul className="benefits-banner__article-list">
                  <li>
                    <span>{t("second_section_business.item_1.title")}</span>
                    <p>{t("second_section_business.item_1.text")}</p>
                  </li>
                  <li>
                    <span>{t("second_section_business.item_2.title")}</span>
                    <p>{t("second_section_business.item_2.text")}</p>
                  </li>
                  <li>
                    <span>{t("second_section_business.item_3.title")}</span>
                    <p>{t("second_section_business.item_3.text")}</p>
                  </li>
                </ul>
                <Link
                  to={signInLink}
                  className="benefits-banner__article-button"
                >
                  {t("second_section_business.button_text")}
                </Link>
              </div>
              <div className="benefits-banner__image-box-right">
                <img
                  src={localStorage.getItem("i18nextLng") === "ar" ? laptopAR : laptopENG}
                  className="benefits-banner__article-img-right"
                  alt="Try for Business Owners"
                />
                <div className="benefits-banner__banner benefits-banner__banner__right">
                  <div className="benefits-banner__banner__icon-box">
                    <Icon
                      name="badge-document"
                      viewBox="0 0 19 19"
                      height="19"
                      width="19"
                    />
                  </div>
                  <div className="benefits-banner__banner__divider-main" />
                  <div className="benefits-banner__banner__divider-secondary" />
                </div>
              </div>
            </div>
            <div className="benefits-banner__main-features">
              <div className="benefits-banner__main-features__title-box">
                <p className="benefits-banner__main-features__secondary-title">
                  {t("main_features.title")}
                </p>
                <h2 className="benefits-banner__main-title">
                  <Trans
                    i18nKey="main_features.main_title"
                    t={t}
                    components={[
                      <span
                        key="highlighted-text"
                        className="benefits-banner__blue-title"
                      ></span>,
                    ]}
                  />
                </h2>
              </div>
              <div className="benefits-banner__main-features__tile-box">
                <div className="benefits-banner__main-features__tile">
                  <Icon
                    name="document"
                    viewBox="0 0 27 34"
                    height="34"
                    width="27"
                    className="benefits-banner__main-features__icon"
                  />
                  <p className="benefits-banner__main-features__tile-title">
                    {t("main_features.cards.item_1.title")}
                  </p>
                  <p className="benefits-banner__main-features__tile-text">
                    {t("main_features.cards.item_1.description")}
                  </p>
                </div>
                <div className="benefits-banner__main-features__tile">
                  <Icon
                    name="left-right"
                    viewBox="0 0 27 34"
                    height="34"
                    width="27"
                    className="benefits-banner__main-features__icon"
                  />
                  <p className="benefits-banner__main-features__tile-title">
                    {t("main_features.cards.item_2.title")}
                  </p>
                  <p className="benefits-banner__main-features__tile-text">
                    {t("main_features.cards.item_2.description")}
                  </p>
                </div>
                <div className="benefits-banner__main-features__tile">
                  <Icon
                    name="contract-medal"
                    viewBox="0 0 32 34"
                    height="34"
                    width="32"
                    className="benefits-banner__main-features__icon"
                  />
                  <p className="benefits-banner__main-features__tile-title">
                    {t("main_features.cards.item_3.title")}
                  </p>
                  <p className="benefits-banner__main-features__tile-text">
                    {t("main_features.cards.item_3.description")}
                  </p>
                </div>
                <div className="benefits-banner__main-features__tile">
                  <Icon
                    name="certificate"
                    viewBox="0 0 35 34"
                    height="34"
                    width="35"
                    className="benefits-banner__main-features__icon"
                  />
                  <p className="benefits-banner__main-features__tile-title">
                    {t("main_features.cards.item_4.title")}
                  </p>
                  <p className="benefits-banner__main-features__tile-text">
                    {t("main_features.cards.item_4.description")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div>
        <Container className="benefits-banner">
          <div className={"benefits-banner__second_article_wrapper"}>
            <div className="benefits-banner__title-box">
              <h2 className="benefits-banner__main-title">
                {t("benefitsForEmployees.titlePart1")}{" "}
                <span className="benefits-banner__green-title">
                  {t("benefitsForEmployees.titlePart2")}
                </span>
              </h2>
              <p className="benefits-banner__secondary-title">
                {t("benefitsForEmployees.content")}
              </p>
            </div>
            <div className="benefits-banner__article-wrapper">
              <div className="benefits-banner__image-box">
                <img
                  src={localStorage.getItem("i18nextLng") === "ar" ? controlAR : controlENG}
                  className="benefits-banner__article-img"
                  alt="Benefits for Employees"
                />
                <div className="benefits-banner__banner benefits-banner__banner__left">
                  <div className="benefits-banner__banner__icon-box-green">
                    <Icon
                      name="badge-document"
                      viewBox="0 0 19 19"
                      height="19"
                      width="19"
                    />
                  </div>
                  <div className="benefits-banner__banner__divider-main" />
                  <div className="benefits-banner__banner__divider-secondary" />
                </div>
              </div>
              <div className="benefits-banner__article-box">
                <h3 className="benefits-banner__article-title">
                  {t("benefitsForEmployees.header")}
                </h3>
                <ul className="benefits-banner__article-list">
                  <li>
                    <span>{t("benefitsForEmployees.getHire.title")}</span>
                    <p>{t("benefitsForEmployees.getHire.content")}</p>
                  </li>
                  <li>
                    <span> {t("benefitsForEmployees.documents.title")}</span>
                    <p>{t("benefitsForEmployees.documents.content")}</p>
                  </li>
                  <li>
                    <span> {t("benefitsForEmployees.upToDate.title")}</span>
                    <p>{t("benefitsForEmployees.upToDate.content")}</p>
                  </li>
                </ul>
                <Link
                  to={signInLink}
                  className="benefits-banner__secondary-article-button"
                >
                  {t("benefitsForEmployees.tryForEmployees")}
                </Link>
              </div>
            </div>
          </div>
          <div className="benefits-banner__main-features">
            <div className="benefits-banner__main-features__title-box">
              <p className="benefits-banner__main-features__secondary-title">
                {t("main_features.title")}
              </p>
              <h2 className="benefits-banner__main-title">
                {t("qiwaCareer.titlePart1")}{" "}
                <span className="benefits-banner__green-title">
                  {t("qiwaCareer.titlePart2")}
                </span>
              </h2>
            </div>
            <div className="benefits-banner__main-features__tile-box">
              <div className="benefits-banner__main-features__tile">
                <Icon
                  name="contract-green"
                  viewBox="0 0 30 35"
                  height="35"
                  width="30"
                  className="benefits-banner__main-features__icon__green"
                />
                <p className="benefits-banner__main-features__tile-title">
                {t("qiwaCareer.contracts.title")}
                </p>
                <p className="benefits-banner__main-features__tile-text">
                {t("qiwaCareer.contracts.description")}
                </p>
              </div>
              <div className="benefits-banner__main-features__tile">
                <Icon
                  name="contract-star"
                  viewBox="0 0 32 35"
                  height="35"
                  width="32"
                  className="benefits-banner__main-features__icon__green"
                />
                <p className="benefits-banner__main-features__tile-title">
                {t("qiwaCareer.rewards.title")}
                </p>
                <p className="benefits-banner__main-features__tile-text">
                {t("qiwaCareer.rewards.description")}
                </p>
              </div>
              <div className="benefits-banner__main-features__tile">
                <Icon
                  name="document"
                  viewBox="0 0 28 35"
                  height="35"
                  width="28"
                  className="benefits-banner__main-features__icon__green"
                />
                <p className="benefits-banner__main-features__tile-title">
                {t("qiwaCareer.policy.title")}
                </p>
                <p className="benefits-banner__main-features__tile-text">
                {t("qiwaCareer.policy.description")}
                </p>
              </div>
              <div className="benefits-banner__main-features__tile">
                <Icon
                  name="salary-certificate"
                  viewBox="0 0 30 35"
                  height="35"
                  width="30"
                  className="benefits-banner__main-features__icon__green"
                />
                <p className="benefits-banner__main-features__tile-title">
                {t("qiwaCareer.certificates.title")}
                </p>
                <p className="benefits-banner__main-features__tile-text">
                {t("qiwaCareer.certificates.description")}
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

BenefitsBanner.propTypes = {
  signInLink: PropTypes.string.isRequired,
};

export default BenefitsBanner;
