import React from "react";
import Link from "../../../../Link/Link";
import Icon from "../../../../Icon/Icon";
import LanguageSwitcher from "../../../../MainMenu/LanguageSwitcher";
import { Styled } from "./AIAssistantHeader.styles";
import { useTranslation } from "react-i18next";

type AIAssistantHeaderProps = {
  blur?: boolean;
};

const AIAssistantHeader = ({ blur }: AIAssistantHeaderProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "aiAssistant",
  });

  return (
    <Styled.HeaderContainer
      $withBlur={blur}
      className="main-menu-navbar main-menu-navbar--desktop main-menu-navbar--desktop__light"
    >
      <Styled.HeaderLogoContainer>
        <Styled.MainLogoWrapper>
          <Link
            textLink={false}
            className="text-decoration-none text-white d-block"
            to="/"
          >
            <Icon
              name={"logo-light"}
              className="d-inline-block align-top"
              viewBox="0 0 66 59"
            />
          </Link>
        </Styled.MainLogoWrapper>
        <Link
          textLink={false}
          className="text-decoration-none text-white d-block"
          to="/ai-assistant"
        >
          <Styled.HeaderSublogoContainer>
            <Icon name="aiQiwa" viewBox="0 0 20 21" />
            {t("qiwa")} <span>beta</span>
          </Styled.HeaderSublogoContainer>
        </Link>
      </Styled.HeaderLogoContainer>
      <LanguageSwitcher variant={"light"} />
    </Styled.HeaderContainer>
  );
};

export default AIAssistantHeader;
