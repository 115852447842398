import React, {useState, useEffect} from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import PropTypes from "prop-types";
import Link from "../Link/Link";
import ActionTile from "../ActionTile/ActionTile";
import Icon from "../Icon/Icon";
import { useTranslation } from "react-i18next";

import "./ActionTilesGroup.scss";
import classNames from "classnames";

export const AccordionWrapper = ({
  title,
  useAccordion,
  titleLink,
  children,
  eventKey,
  underline,
  onAccordionToggle
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "article",
  });
  const [clicked, setClicked] = useState(false);
  
  const handleClick = () => {
    setClicked(prev => !prev); 
    onAccordionToggle(!clicked);
  };

  useEffect(() => {
    if (clicked) {
      const accordionId = `accordion-${eventKey || title.replace(/\s+/g, '-')}`;
      const accordionElement = document.querySelector(`#${accordionId}`);

      if (accordionElement) {
        setTimeout(() => { 
          const elementPosition = accordionElement.getBoundingClientRect().top + window.pageYOffset - 100;
          window.scrollTo({ top: elementPosition, behavior: "smooth" });
        }, 300); 
      }
    }
  }, [clicked, eventKey]);

  if (window.innerWidth < 768 && useAccordion) {
    const accordionId = `accordion-${eventKey || title.replace(/\s+/g, '-')}`;
    return (
      <Accordion.Item eventKey={eventKey || title} data-testid={"action-tile-accordion-test"}>
        <Accordion.Header  id={accordionId} onClick={handleClick} bsPrefix={`accordion-header-custom ${underline === false ? "button-without-underline" : "" }`}>{title}</Accordion.Header>
        <Accordion.Body>
          {titleLink && (
            <Link
              to={titleLink}
              className="action-tiles-group__all-articles-link"
            >
            {t("allArticles")}
            </Link>
          )}
          {children}
        </Accordion.Body>
      </Accordion.Item>
    );
  } else {
    return <>{children}</>;
  }
};

AccordionWrapper.propTypes = {
  title: PropTypes.string,
  useAccordion: PropTypes.bool,
  titleLink: PropTypes.bool,
  children: PropTypes.node,
  eventKey: PropTypes.string,
  underline: PropTypes.bool,
  onAccordionToggle: PropTypes.func,
};

const ActionTilesGroup = ({
  title,
  titleLink,
  description,
  cards,
  groupCta,
  theme,
  useAccordion,
  forcedTheme,
  eventKey,
  underline,
  desktopGrid,
  onAccordionToggle,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "homepage",
  });
  const handleAccordionToggle = (isOpen) => {
    if (onAccordionToggle) {
      onAccordionToggle(isOpen); 
    }
  };
  
  return (
    <div
      className={classNames(
        "action-tiles-group",
        `action-tiles-group--${theme ? theme : "neutral"}`,
        useAccordion ? "" : "action-tiles-group--without-accordion"
      )}
      data-testid="action-tiles-group-test"
    >
      <AccordionWrapper
        title={title}
        titleLink={titleLink}
        useAccordion={useAccordion}
        eventKey={eventKey}
        underline={underline}
        onAccordionToggle={handleAccordionToggle}
      >
        <Container>
          <Row>
            <Col md="12" lg="12">
              {titleLink && !groupCta ? (
                <div className="action-tiles-group__title-link">
                  <h4 className="action-tiles-group__title">
                    <div className="action-tiles-group__title__sentence">{title}</div>
                      <Link
                        to={titleLink}
                        className="action-tiles-group__all-articles-link"
                      >
                      {t("goToAllArticles")}
                      <Icon
                        name={
                          localStorage.getItem("i18nextLng") === "en"
                            ? "arrow-right"
                            : "arrow-left"
                        }
                        viewBox={"0 0 24 24"}
                        className="arrow-right-icon"
                      />
                      </Link>
                  </h4>
                </div>
              ) : (
                <h4 className="action-tiles-group__title">{title}</h4>
              )}
              {description && (
                <p className="action-tiles-group__description">{description}</p>
              )}
            </Col>
          </Row>
          <Row xs="1" md="2" lg={desktopGrid} className="action-tiles-group__cards-row">
            {cards.map((card, index) => {
              return (
                <Col key={index} className="action-tiles-group__card-column">
                  <Link
                    to={card.url || card?.alias}
                    textLink={false}
                    className="action-tiles-group__link"
                  >
                    <ActionTile
                      title={card.title}
                      description={card.description}
                      theme={forcedTheme ? theme : card.theme}
                      tag={card.tag || card.category}
                      type={card.type}
                    />
                  </Link>
                </Col>
              );
            })}
          </Row>
          {groupCta && (
            <span className="action-tiles-group__group-cta">
              <Link
                to={groupCta.url}
                textLink={true}
                underlined={true}
                revert={true}
                variant="black"
                label={groupCta.label || groupCta.title}
              >
                <Icon name="arrow-right" className="arrow-right-icon" />
              </Link>
            </span>
          )}
        </Container>
      </AccordionWrapper>
    </div>
  );
};


ActionTilesGroup.propTypes = {
  title: PropTypes.string,
  titleLink: PropTypes.bool,
  description: PropTypes.string,
  onAccordionToggle: PropTypes.func,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      theme: PropTypes.string,
      tag: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  groupCta: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    label: PropTypes.string,
  }),
  theme: PropTypes.string,
  useAccordion: PropTypes.bool,
  forcedTheme: PropTypes.bool,
  eventKey: PropTypes.string,
  underline: PropTypes.bool,
  desktopGrid: PropTypes.number,
};

ActionTilesGroup.defaultProps = {
  title: "",
  titleLink: false,
  description: "",
  desktopGrid: 3,
  cards: [],
  theme: "business",
  useAccordion: true,
  forcedTheme: false,
};

export default ActionTilesGroup;
