import styled, { css } from "styled-components";
import { respondAbove } from "@takamol/qiwa-design-system/styles/mediaQueries";

const LinkContentContainer = styled.div<{$variant: string}>`
  margin: 0;
  padding: 6px 12px;
  background: rgba(255, 255, 255, 0.16);
  border: 1px solid rgba(200, 201, 203, 0.24);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  @media ${respondAbove.lg} {    
    margin-top: 0;

    ${(props) => {
    switch (props.$variant) {
      case "article":
        return css`
          width: 100%;
          max-width: 297px;
          margin-top: 56px;
          margin-left: 0;
          margin-right: 0;
        `;
      default:
        return css`
          width: max-content;
          margin-top: 0;
        `;
    }
  }}
  }

  ${(props) => {
    switch (props.$variant) {
      case "article":
        return css`
          background: linear-gradient(180deg, #E3ECFF 0%, rgba(227, 236, 255, 0.00) 107.22%);
          height: 76px;
          margin: 8px 16px;
          width: 100%;
          max-width: calc(100% - 32px);

          ${TopText} {
            color: ${props.theme.colors.grayscale_900};
          }

          ${BottonText} {
            color: ${props.theme.colors.grayscale_900};
          }
        `;
      default:
        return css`
          backdrop-filter: blur(11px);
          height: 64px;
          margin-top: 12px;
        `;
    }
  }}
`;

const TextContainer = styled.div`
  margin: 0;
`

const TopText = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.grayscale_50};
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;

  span {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
  }
`
const BottonText = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.business_100};
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
`

export const Styled = {
  LinkContentContainer,
  TextContainer,
  TopText,
  BottonText
}