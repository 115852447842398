import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "./Article.scss";
import Helmet from "react-helmet";
import ArticleBody from "./Sections/ArticleBody/ArticleBody";
import DidYouKnow from "./Sections/DidYouKnow/DidYouKnow";
import Hero from "./Sections/HeroTypeOne/HeroSection";
import Text from "../Shared/Text/Text";
import TableOfContents from "../Shared/TableOfContent/TableOfContent";
import Banner from "../../../Banner/Banner";
import Feedback from "../../../Feedback/Feedback";
import IconCardsGroup from "../../../IconCardsGroup/IconCardsGroup";
import { MainContext } from "../../../contexts/context";
import classnames from "classnames";
import _, { isEmpty } from "lodash";
import HeroSection from "./Sections/HeroTypeTwo/HeroSection";
import VideoBanner from "./Sections/VideoBanner/Banner";
import TimeDisplay from "./Sections/TimeDisplay/TimeDisplay";
import Accordion from "../Shared/Accordion/Accordion";
import RelatedTopics from "../Shared/RelatedTopics/RelatedTopics";
import NotFoundError from "../../ErrorPages/NotFound/NotFound";
import ServerError from "../../ErrorPages/ServerError/ServerError";
import { useTranslation } from "react-i18next";
import { checkLanguageInUrl } from "../../../../utils/checkLanguageInUrl";
import { PageLoader } from "../../../Loader/PageLoader";
import { Divider } from "@takamol/qiwa-design-system/components";
import useNavigationHistory from "../../../../hooks/useNavigationHistory";
import { extractSpecialUrlsFromHistory } from "../../../../utils/extractSpecialUrlsFromHistory";
import useFetchContent from "../../../../hooks/useFetchContent";
import AIAssistantEntryLink from "../../AIAssistant/components/AIAssistantEntryLink";

const Article = () => {
  const { param1, param2, alias } = useParams();
  const contextValue = useContext(MainContext);
  const { theme } = contextValue;
  const [jsonData, setJsonData] = useState(null);
  const [sortedJsonData, setSortedJsonData] = useState(null);
  const [isNotFound, setIsNotFound] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [isServerError, setIsServerError] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { t } = useTranslation("translation", { keyPrefix: "article" });
  const navigationHistory = useNavigationHistory();
  const specialUrls = extractSpecialUrlsFromHistory(navigationHistory);
  const [isSaudiLaborSystem, setIsSaudiLaborSystem] = useState(null);
  const contentByType = useFetchContent();

  const [isScrolled, setIsScrolled] = useState(false);

  function removeTrailingChars(str, chars) {
    let end = str.length;
    while (end > 0 && chars.includes(str[end - 1])) {
      end--;
    }
    return str.substring(0, end);
  }

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setIsScrolled(position > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        setIsDataLoading(true);
        const storedLanguage = localStorage.getItem("i18nextLng");
        const urlLanguage = checkLanguageInUrl(window.location.pathname);
        const language =
          storedLanguage === urlLanguage ? storedLanguage : urlLanguage;
        const urlPath = [param1, param2, alias].filter(Boolean).join("/");
        let endpoint = `${process.env.REACT_APP_FULL_URL}/api/v1/entity?alias=/${urlPath}`;
        if (specialUrls?.serviceOverview) {
          endpoint += `&service=${specialUrls.serviceOverview}`;
        }

        if (specialUrls?.serviceCategory && specialUrls?.serviceCategory !== "/labor-education") {
          endpoint += `&category=${specialUrls.serviceCategory}`;
        }

        if (param1 === "definitions" && specialUrls?.definitionsBeforeLast) {
          endpoint += `&reference=${specialUrls.definitionsBeforeLast}`;
        }
        if (language === "ar") {
          endpoint += "&language=ar";
        }

        if (specialUrls?.saudiLaborSystem) {
          setIsSaudiLaborSystem(specialUrls?.saudiLaborSystem);
        }
        const response = await fetch(endpoint);
        if (!response) return;

        if (!response.ok) {
          setIsDataLoading(false);
          if (response.status === 404) {
            setIsNotFound(true);
            return;
          }
          if ([500, 502, 503, 504].includes(response.status)) {
            setIsServerError(response.status);
            return;
          }
          throw new Error("Failed to fetch data");
        }
        const data = response ? await response.json() : {};

        const orderedComponents = [];
        _.forOwn(data, (value, key) => {
          if (_.isArray(value)) {
            setIsDataLoading(false);
            value.forEach((item) => {
              orderedComponents.push({
                type: key,
                data: item,
              });
            });
          }
        });

        const sortedComponents = _.sortBy(orderedComponents, ["order"]);
        setSortedJsonData(sortedComponents);
        setJsonData(data);
      } catch (error) {
        setIsDataLoading(false);
        console.log(error);
      }
    };

    fetchArticleData();
  }, [alias, param1, param2, theme, specialUrls?.definitionsBeforeLast]);
  useEffect(() => {
    if (sortedJsonData) {
      if (window.location.hash) {
        let hashValue = decodeURIComponent(window.location.hash.substring(1));
        const normalizedHash = removeTrailingChars(hashValue, "-؟?");
        let element = document.getElementById(hashValue);
        if (!element) {
          element =
            document.getElementById(`${normalizedHash}?`) ||
            document.getElementById(`${normalizedHash}-؟`) ||
            document.getElementById(`${normalizedHash}؟`);
        }
        if (!element) {
          element = document.getElementById(normalizedHash);
        }
        if (element) {
          const elementPosition =
            element.getBoundingClientRect().top + window.pageYOffset - 162;
          window.scrollTo({ top: elementPosition, behavior: "instant" });
        }
      } else {
        window.scrollTo({ top: 0, behavior: "instant" });
      }
    }
  }, [sortedJsonData]);

  useEffect(() => {
    if (!isEmpty(jsonData?.popular_questions) && window.location.hash) {
      let hashValue = decodeURIComponent(window.location.hash.substring(1));
      let foundIndex = jsonData?.popular_questions?.findIndex(
        (q) => formatTitleForHash(q.title) === hashValue
      );
      if (foundIndex === -1 && isEmpty(jsonData?.popular_questions)) {
        const questionMark = theme === "arabic" ? "؟" : "?";
        hashValue = `${hashValue}${questionMark}`;
        foundIndex = jsonData?.popular_questions?.findIndex(
          (q) => formatTitleForHash(q.title) === hashValue
        );
      }
      if (foundIndex !== -1) {
        setOpenIndex(foundIndex);
      }
    }
  }, [jsonData, theme]);

  const formatTitleForHash = (title) => {
    return title.replace(/\s+/g, "-").replace(/؟/g, "-؟").toLowerCase();
  };

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  if (isNotFound) {
    return <NotFoundError />;
  }

  if (isServerError) {
    return <ServerError />;
  }

  function removeBasicHtml(sortedJsonData) {
    return _.cloneDeepWith(sortedJsonData, (value) => {
      if (_.isString(value)) {
        return value.replace(", basic_html", "").replace(", full_html", "");
      }
    });
  }
  const cleanedData = removeBasicHtml(sortedJsonData);
  const heroSection = cleanedData?.find(
    (section) => section.type === "heroSection"
  );
  const headline = heroSection ? heroSection.data.headline : "Article";
  sessionStorage.setItem("pageTitle", headline);
  return (
    <div className="journey-article" data-testid="test-journey-article-wrapper">
      <Helmet>
        <title>{headline}</title>
        <meta property="og:title" content={headline + " | Qiwa"} />
      </Helmet>
      {isDataLoading && <PageLoader />}
      {cleanedData?.map((component, index) => {
        switch (component.type) {
          case "heroSection":
            if (isEmpty(component?.data?.video)) {
              return (
                <HeroSection
                  key={index}
                  data={component.data}
                  breadcrumbs={jsonData?.breadcrumbs}
                  saudiLaborSystem={isSaudiLaborSystem}
                />
              );
            }
            return (
              <Hero
                key={index}
                data={component.data}
                breadcrumbs={jsonData?.breadcrumbs}
              />
            );
          default:
            return null;
        }
      })}
      <Container className="p-0">
        <Row className="m-0">
          {jsonData?.type !== "content" && jsonData && !isDataLoading && (
            <Col lg={4} className="table-of-contents-sticky p-0">
              <div
                className={classnames({
                  "sticky-top": true,
                  "sticky-top--rtl": theme !== "english",
                  scrolled: isScrolled,
                })}
              >
                <AIAssistantEntryLink variant="article"/>
                <TableOfContents
                  title="Table of contents"
                  contents={cleanedData
                    .flatMap((item) =>
                      item.type === "tableOfContents" ? item.data.data : []
                    )
                    .filter((subItem) => subItem)}
                />
              </div>
            </Col>
          )}
          <Col
            lg={8}
            className={classnames({
              "journey-article__body": true,
              "journey-article__body--rtl": theme !== "english",
            })}
          >
            {cleanedData?.map((component, index) => {
              switch (component.type) {
                case "description_paragraph":
                  return (
                    <Text
                      key={index}
                      text={component.data.description}
                      copyText={true}
                    />
                  );
                case "tagsSection":
                  return (
                    <ArticleBody
                      key={index}
                      data={component.data}
                      order={jsonData?.type}
                    />
                  );
                case "banner":
                  if (component.data?.button_title) {
                    return (
                      <div className="full-width-banner">
                        <VideoBanner key={index} data={component.data} />
                      </div>
                    );
                  }
                  return (
                    <div className="full-width-banner mt-5 mb-5">
                      <DidYouKnow key={index} data={component.data} />
                    </div>
                  );
                default:
                  return null;
              }
            })}
            {!isEmpty(jsonData?.popular_questions) && (
              <div className="article__questions">
                <p className="article__popular-questions">
                  <Text copyText={true} text={t("popularQuestions")} />
                </p>
                {jsonData?.popular_questions?.map((component, index) => (                  
                  <div className="article__question-container" key={index}>
                    <Accordion
                      question={component?.title}
                      index={formatTitleForHash(component?.title)}
                      isOpen={openIndex === index}
                      toggleAccordion={() => toggleAccordion(index)}
                    >
                      <ArticleBody
                        data={component?.components[0]?.questions[0]}
                      />
                    </Accordion>
                  </div>
                ))}
              </div>
            )}

            <div>
              <TimeDisplay
                createdTime={jsonData?.times?.createdTime}
                updatedTime={jsonData?.times?.updatedTime}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <RelatedTopics data={jsonData?.relatedTopics} />
      <Feedback />
      <Banner variant={"body"} />
      <IconCardsGroup
        groupData={contentByType?.why_qiwa_platform}
        variant="dark"
      />
    </div>
  );
};

export default Article;
