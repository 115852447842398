import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import ArticleBody from "../ArticleContainer/Sections/ArticleBody/ArticleBody";
import Text from "../Shared/Text/Text";
import Helmet from "react-helmet";
import TableOfContents from "../Shared/TableOfContent/TableOfContent";
import Banner from "../../../Banner/Banner";
import Feedback from "../../../Feedback/Feedback";
import HeroSection from "./ServiceOverviewHero";
import { MainContext } from "../../../contexts/context";
import classnames from "classnames";
import _ from "lodash";
import "./ServiceOverviewPage.scss";
import RelatedTopics from "../Shared/RelatedTopics/RelatedTopics";
import NotFoundError from "../../ErrorPages/NotFound/NotFound";
import ServerError from "../../ErrorPages/ServerError/ServerError";
import { checkLanguageInUrl } from "../../../../utils/checkLanguageInUrl";
import { PageLoader } from "../../../Loader/PageLoader";
import useNavigationHistory from "../../../../hooks/useNavigationHistory";
import { extractSpecialUrlsFromHistory } from "../../../../utils/extractSpecialUrlsFromHistory";
import AIAssistantEntryLink from "../../AIAssistant/components/AIAssistantEntryLink";

interface Params {
  [key: string]: string | undefined;
}

interface ComponentData {
  type: string;
  order: number;
  data: any;
}
interface Section {
  section: {
    type: string;
  };
  type: string;
}

const ServiceOverviewPage: React.FC = () => {
  const { param1, param2, alias } = useParams<Params>();
  const contextValue = useContext(MainContext);
  const { theme } = contextValue;
  const [jsonData, setJsonData] = useState<any>(null);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [sortedJsonData, setSortedJsonData] = useState<ComponentData[] | null>(
    null
  );
  const navigationHistory = useNavigationHistory();
  const specialUrls = extractSpecialUrlsFromHistory(navigationHistory);

  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        setIsDataLoading(true);
        const storedLanguage = localStorage.getItem("i18nextLng");
        const urlLanguage = checkLanguageInUrl(window.location.pathname);
        const language =
          storedLanguage === urlLanguage ? storedLanguage : urlLanguage;
        const urlPath = [param1, param2, alias].filter(Boolean).join("/");
        let endpoint = `${process.env.REACT_APP_FULL_URL}/api/v1/entity?alias=/service-overview/${urlPath}`;
        if (specialUrls?.serviceCategory) {
          endpoint += `&category=${specialUrls.serviceCategory}`;
        }
        if (language === "ar") {
          endpoint += "&language=ar";
        }
        const response = await fetch(endpoint);
        if (!response.ok) {
          setIsDataLoading(false);
          if (response.status === 404) {
            setIsNotFound(true);
            return;
          }
          if ([500, 502, 503, 504].includes(response.status)) {
            setIsServerError(true);
            return;
          }

          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        const orderedComponents: ComponentData[] = [];
        _.forOwn(data, (value, key) => {
          if (_.isArray(value)) {
            setIsDataLoading(false);
            value.forEach((item) => {
              if (_.has(item, "order")) {
                orderedComponents.push({
                  type: key,
                  order: parseInt(item.order),
                  data: item,
                });
              }
            });
          }
        });

        const sortedComponents = _.sortBy(orderedComponents, ["order"]);
        setSortedJsonData(sortedComponents);
        setJsonData(data);
      } catch (error) {
        setIsDataLoading(false);
        console.error(error);
      }
    };

    fetchArticleData();
  }, [theme]);
  if (isNotFound) {
    return <NotFoundError />;
  }
  if (isServerError) {
    return <ServerError />;
  }

  function removeBasicHtml(sortedJsonData: ComponentData[] | null) {
    return _.cloneDeepWith(sortedJsonData, (value) => {
      if (_.isString(value)) {
        return value.replace(", basic_html", "").replace(", full_html", "");
      }
    });
  }
  const cleanedData = removeBasicHtml(sortedJsonData);
  const heroSection = cleanedData?.find(
    (section: Section) => section.type === "heroSection"
  );
  const headline = heroSection ? heroSection.data.headline : "Article";

  return (
    <div className="service-overview-page">
      <Helmet>
        <title>{headline}</title>
        <meta property="og:title" content={headline + " | Qiwa"} />
      </Helmet>
      {isDataLoading && <PageLoader />}
      {cleanedData?.map((component: ComponentData, index: number) => {
        switch (component.type) {
          case "heroSection":
            return (
              <HeroSection
                key={index}
                title={component?.data?.headline}
                breadcrumbs={jsonData.breadcrumbs}
              />
            );
        }
      })}
      <Container className="p-0">
        <Row className="m-0">
          {jsonData?.tableOfContents && (
            <Col lg={4} className="p-0">
              <div
                className={classnames({
                  "sticky-top": true,
                  "sticky-top--rtl": theme !== "english",
                })}
              >
                <AIAssistantEntryLink variant="article" />
                {cleanedData?.map((component: ComponentData, index: number) => {
                  switch (component.type) {
                    case "tableOfContents":
                      return (
                        <TableOfContents
                          title="Objectives"
                          key={index}
                          contents={component?.data?.data}
                        />
                      );
                  }
                })}
              </div>
            </Col>
          )}
          <Col
            lg={8}
            className={classnames({
              "journey-article__body": true,
              "journey-article__body--rtl": theme !== "english",
            })}
          >
            {cleanedData?.map((component: ComponentData, index: number) => {
              switch (component.type) {
                case "description_paragraph":
                  return (
                    <Text
                      key={index}
                      text={component.data.description}
                      copyText={true}
                    />
                  );
                case "tagsSection":
                  return (
                    <ArticleBody
                      key={index}
                      data={component.data}
                      order={jsonData?.type}
                      className={"Tertiary"}
                    />
                  );
                default:
                  return null;
              }
            })}
          </Col>
        </Row>
      </Container>
      <RelatedTopics data={jsonData?.relatedTopics} />
      <Feedback />
      <Banner variant={"body"} />
    </div>
  );
};

export default ServiceOverviewPage;
