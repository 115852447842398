import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import Icon from "../../../../Icon/Icon";
import "./IconTooltip.scss";

interface IconTooltipProps {
  
  onClick?: any;
  iconClassName?: string;
}

const IconTooltip: React.FC<IconTooltipProps> = ({
  onClick,
  iconClassName,
}) => {
  const [isTooltipCopied, setIsTooltipCopied] = useState<boolean>(false);
  const [tooltipText, setTooltipText] = useState<string>("");

  const { t } = useTranslation("translation", { keyPrefix: "article" });

  useEffect(() => {
    setTooltipText(t("copyText"));
  }, []);

  const handleClick = (e: any) => {
    setTooltipText(t("copied"));
    setIsTooltipCopied(true);
    if (onClick) {
      onClick();
    }
  };

  const handleMouseLeave = (e: any) => {
    setIsTooltipCopied(false);
    setTooltipText(t("copyText"));
  };

  return (
    <button
      className="icon-tooltip-container"
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <Icon
        name="copy-adjustable"
        viewBox="0 0 24 24"
        width="24px"
        height="24px"
        className={classnames(
          "icon-tooltip-container__icon",
          iconClassName,
          isTooltipCopied && "icon-tooltip-container__icon--copied"
        )}
      />
      <div className="icon-tooltip-container__container">{tooltipText}</div>
    </button>
  );
};

export default IconTooltip;
