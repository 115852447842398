import React, { useContext, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { MainContext } from "./components/contexts/context.js";
import Header from "./components/Header/Header.jsx";
import Home from "./components/pages/Home/Home.jsx";
import Footer from "./components/Footer/Footer.jsx";
import Contribute from "./components/pages/Contribute.js";
import ArticlePage from "./components/pages/Article/Article.jsx";
import LandingPage from "./components/pages/LandingPage/LandingPage.jsx";
import ToolsCalculators from "./components/pages/ToolsCalculators/ToolsCalculators.jsx";
import CertificateCalcPage from "./components/pages/ToolsCalculators/Calculators/CertificateCalcPage/CertificateCalcPage.jsx";
import EndOfServiceReward from "./components/pages/ToolsCalculators/Calculators/EndOfServiceRewardPage/EndOfServiceRewardPage.jsx";
import LinkingOccupationsPage from "./components/pages/ToolsCalculators/Calculators/LinkingOccupationsPage/LinkingOccupationsPage.jsx";
import WorkPermitCalcPage from "./components/pages/ToolsCalculators/Calculators/WorkPermitCalcPage/WorkPermitCalcPage.jsx";
import NitaqatCalcPage from "./components/pages/ToolsCalculators/Calculators/NitaqatCalcPage/NitaqatCalcPage.jsx";
import JourneyArticle from "./components/pages/Articles/ArticleContainer/Article.jsx";
import ServiceCategoryPage from "./components/pages/Articles/ServiceCategoryPage/ServiceCategoryPage.jsx";
import ServerError from "./components/pages/ErrorPages/ServerError/ServerError.jsx";
import SessionTimeout from "./components/pages/ErrorPages/SessionTimeout/SessionTimeout.jsx";
import SearchPage from "./components/pages/SearchPage/SearchPage.tsx";
import PermissionError from "./components/pages/ErrorPages/PermissionError/PermissionError.jsx";
import SLAPage from "./components/pages/SLA/SLAPage.tsx";
import SaudiLaborSytem from "./components/pages/SaudiLaborSystem/SaudiLaborSytem.jsx";
import NotFoundError from "./components/pages/ErrorPages/NotFound/NotFound.jsx";
import CallingServicePage from "./components/pages/CallingServicePage/CallingServicePage.tsx";
import ServiceOverviewPage from "./components/pages/Articles/ServiceOverviewPage/ServiceOverviewPage.tsx";
import EmployeesSkillsCalcPage from "./components/pages/ToolsCalculators/Calculators/EmployeesSkillsCalcPage/EmployeesSkillsCalcPage.tsx";
import usePageTracker from "./hooks/usePageTracker.jsx";
import ServicesPage from "./components/pages/ServicesPage/ServicesPage.tsx";
import LaborEducation from "./components/pages/LaborEducation/LaborEducation.tsx";
import LaboreEducationArticles from "./components/pages/LaborEducation/LaborEducationArticles/LaborEducationArticles.jsx";  
import usePersonaTracker from "./hooks/usePersonaTracker.js";

function LocalizedApp() {
  const { headerData, footerData, error } = useContext(MainContext);
  usePersonaTracker()

  const navigate = useNavigate();
  useEffect(() => {
    if ([500, 504, 502, 503].includes(error)) {
      navigate(`${error}`);
    }
  }, [error]);

  usePageTracker();

  return (
    <>
      <Header variant={headerData?.variant} />
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="*" element={<NotFoundError />}></Route>
        <Route path="/services" element={<ServicesPage />}></Route>
        <Route path="/social" element={<Contribute />}></Route>
        <Route path="/terms-and-conditions" element={<ArticlePage />}></Route>
        <Route path="/terms-and-conditions/qiwa-wallet" element={<ArticlePage />}></Route>
        <Route path="/contact-terms-of-use" element={<ArticlePage />}></Route>
        <Route path="/contact" element={<ArticlePage />}></Route>
        <Route path="/privacy-policy" element={<ArticlePage />}></Route>
        <Route path="/terms-and-conditions-app" element={<ArticlePage />}></Route>
        <Route path="/privacy_app" element={<ArticlePage />}></Route>
        <Route path="/explore-qiwa" element={<LandingPage />}></Route>
        <Route path=":param1/:param2/:alias" element={<JourneyArticle />}></Route>
        <Route path="/service-category/:alias" element={<ServiceCategoryPage />} />
        <Route
          path="/service-overview/:param1/:param2/:alias"
          element={<ServiceOverviewPage />}
        ></Route>
        <Route path={`${error}`} element={<ServerError code={error} />}></Route>
        <Route path="/440" element={<SessionTimeout />}></Route>
        <Route path="/500" element={<ServerError code={500} />}></Route>
        <Route path="/502" element={<ServerError code={502} />}></Route>
        <Route path="/503" element={<ServerError code={503} />}></Route>
        <Route path="/504" element={<ServerError code={504} />}></Route>
        <Route path="/403" element={<PermissionError />}></Route>
        <Route path="/401" element={<PermissionError code={401} />}></Route>
        <Route
          path="/labor-law"
          element={<SaudiLaborSytem />}
        ></Route>
        <Route
          path="/labor-education"
          element={<LaborEducation />}
        ></Route>
        <Route path="/labor-education/:alias" element={<LaboreEducationArticles />}></Route>
        <Route
          path="/tools-and-calculators/certificate"
          element={<CertificateCalcPage />}
        ></Route>
        <Route
          path="/tools-and-calculators/end-of-service-reward-calculator"
          element={<EndOfServiceReward />}
        ></Route>
        <Route
          path="/tools-and-calculators/linking-occupations-to-activities"
          element={<LinkingOccupationsPage />}
        ></Route>
        <Route
          path="/tools-and-calculators/work-permit-calculator"
          element={<WorkPermitCalcPage />}
        ></Route>
        <Route path="/search" element={<SearchPage />}></Route>
        <Route
          path="/tools-and-calculators/nitaqat-calculator"
          element={<NitaqatCalcPage />}
        ></Route>
        {/* <Route
          path="/tools-and-calculators/employees-skills-classification"
          element={<EmployeesSkillsCalcPage />}
        ></Route> */}
        <Route path="/service-level-agreement" element={<SLAPage />}></Route>
        <Route path="/customer-video-calling-service" element={<CallingServicePage />}></Route>
      </Routes>
      {footerData && footerData.sections && (
        <Footer
          sections={footerData.sections}
          ctaBar={footerData.ctaBar}
          socialLinks={footerData.socialLinks}
          secondaryLinks={footerData.secondaryLinks}
          footerLogos={footerData.footerImageUrls}
          copyright={footerData.copyrights}
        />
      )}
    </>
  );
}
export default LocalizedApp;
