import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Link from "../Link/Link";
import { MainContext } from "../contexts/context";
import NitaqatCalcForm from "./NitaqatCalcForm/NitaqatCalcForm";
import NitaqatCalcResult from "./NitaqatCalcResult/NitaqatCalcResult";
import InfoBox from "./InfoBox/InfoBox";

import "./NitaqatCalculator.scss";

const NitaqatCalculator = ({displayNote}) => {
  const { theme } = useContext(MainContext);
  const { t } = useTranslation("translation", {
    keyPrefix: "nitaqatCalc",
  });
  const [displayResult, setDisplayResult] = useState(false);
  const [comparewithExpectedNitaqat, setComparewithExpectedNitaqat] =
    useState(false);
  const [nitaqatCalcResult, setNitaqatCalcResult] = useState({});
  const [recommendation, setRecommendation] = useState({});

  useEffect(() => {
    if (
      nitaqatCalcResult["current"] &&
      nitaqatCalcResult["current"]["recommendation"]
    ) {
      setRecommendation(nitaqatCalcResult["current"]["recommendation"]);
    }
  }, [nitaqatCalcResult]);

  const onShowResult = (value) => {
    setDisplayResult(value);
    displayNote(value);
  }

  return (
    <>
      <div
        className={classNames({
          "nitaqat-calculator": true,
          "nitaqat-calculator--rtl": theme === "arabic",
        })}
        data-testid="nitaqat-calculator-test"
      >
        <div className="nitaqat-calculator__content">
          <div className="nitaqat-calculator__text-content">
            <h2 className="nitaqat-calculator__title">{t("title")}</h2>
            <p className="nitaqat-calculator__description">{t("description1")}</p>
            <p className="nitaqat-calculator__description">
              {t("description2")}
            </p>
            <p className="nitaqat-calculator__description">
              {t("description3")}
            </p>
            <p className="nitaqat-calculator__description nitaqat-calculator__description-small">
              <span className="nitaqat-calculator__description__text">
                {t("readMore")}
              </span>
              <Link
                label={t("readMoreLinkLabel")}
                to="/business-owners/manage-establishment/what-nitaqat-and-how-it-calculated"
                underlined
              />
            </p>
          </div>
          <NitaqatCalcForm
            checkCompare={comparewithExpectedNitaqat}
            setCheckCompare={setComparewithExpectedNitaqat}
            setDisplayResult={onShowResult}
            setNitaqatCalcResult={setNitaqatCalcResult}
          />
        </div>
        {displayResult && nitaqatCalcResult["current"] && (
          <>
            <div className="nitaqat-calculator__divider" />
            <div className="nitaqat-calculator__result">
              <h1 className="nitaqat-calculator__result__title">{t("results")}</h1>
              <div className="nitaqat-calculator__result__container">
                <div
                  className={classNames({
                    "nitaqat-calculator__result__container__centered": true,
                    "mr-2": comparewithExpectedNitaqat,
                  })}
                >
                  <NitaqatCalcResult
                    data={nitaqatCalcResult["current"]}
                    title={t("nitaqatCalcResult.currentNitaqat")}
                    nitaqatLevelTitle={t("nitaqatCalcResult.currentNitaqat")}
                    isExpectedVariant={false}
                  />

                  {!comparewithExpectedNitaqat &&
                    Object.values(recommendation).length > 0 && (
                      <InfoBox className="nitaqat-calculator__result__container__info--box">
                        <div>
                          <span className="nitaqat-calculator__result__container__info--box__title">
                            {t("recommendation.title")}
                          </span>
                          <ul>
                            {Object.values(recommendation).map(
                              (recommendation, index) => (
                                <li key={index}>
                                  {theme === "english"
                                    ? recommendation["en"]
                                    : recommendation["ar"]}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </InfoBox>
                    )}
                </div>
                {comparewithExpectedNitaqat && nitaqatCalcResult["expected"] && (
                  <NitaqatCalcResult
                    data={nitaqatCalcResult["expected"]}
                    className="width-50"
                    title={t("nitaqatCalcResult.expectedNitaqat")}
                    nitaqatLevelTitle={t("nitaqatCalcResult.expectedNitaqat")}
                    isExpectedVariant={true}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

NitaqatCalculator.propTypes = {
  displayNote: PropTypes.func,
};

export default NitaqatCalculator;
