import styled from "styled-components";
import { respondAbove } from "@takamol/qiwa-design-system/styles/mediaQueries";

const AnswersPageWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 668px;
  height: calc(100vh - 186px);
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 100%;
  position: fixed;
  padding: 72px 12px 0;
  z-index: 100;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${respondAbove.lg} {
    height: calc(100vh - 148px);
    gap: 24px;
    padding: 128px 0 0;
  }
`

const ScrollBtnWrapper = styled.div<{$isRtl: boolean}>`
  position: fixed;
  bottom: 183px;
  inset-inline-start: 50%;
  transform: ${props => props.$isRtl ? "translateX(50%)" : 'translateX(-50%)'};
  background-color: ${props => props.theme.colors.grayscale_0};
  width: 40px;
  height: 40px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10), 0px 8px 24px 0px rgba(0, 0, 0, 0.12);
  z-index: 100;

  button[data-component="IconButton"] {
    width: 100%;
    height: 100%;
  }

  @media ${respondAbove.lg} {
    bottom: 157px; 
  }
`

const AnswersBottom = styled.div`
  width: 100%;
  height: 0;
  margin: 0;
`

const QuestionInputWrapper = styled.div<{$theme: string}>`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  position: fixed;
  bottom: 0;
  inset-inline-start: 50%;
  transform: ${props => props.$theme === 'english' ? 'translate(-50%, 0)' : 'translate(50%, 0)'};
  width: 100%;
  max-width: 668px;
  z-index: 101;
  padding: 0 12px;

  @media ${respondAbove.lg} {
    padding: 0;
  }
`
const FooterText = styled.p`
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.grayscale_600};
`

export const Styled = {
  AnswersPageWrapper,
  AnswersContainer,
  ScrollBtnWrapper,
  AnswersBottom,
  QuestionInputWrapper,
  FooterText,
}