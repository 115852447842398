import styled, { css } from "styled-components";

const MsgContainer = styled.div<{ $author: string }>`
  display: block;
  max-width: calc(100% - 72px);
  width: max-content;

  ${(props) => {
    switch (props.$author) {
      case "user":
        return css`
          background-color: #E0EEFF;
          padding: 12px 24px;
          border-radius: 12px;
          align-self: flex-end;
        `;
      default:
        return css`
          background-color: ${props.theme.colors.grayscale_100};
          padding: 86px 24px 32px;
          border-radius: 24px;
          align-self: flex-start;
          position: relative;
          z-index: 0;
        `;
    }
  }}
`

const MsgContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`

const MsgAvatar = styled.div`
  width: 100%;
  position: absolute;
  top: 0px;
  inset-inline-start: -5px;
  z-index: 1;
  user-select: none;
  pointer-events: none;

  img {
    width: 110px;
    height: 110px;
  }
`

const MsgText = styled.div`
  margin: 0;
  width: 100%;
  z-index: 5;
`

const MsgReactionsWrapper = styled.div`
  width: 100%;
`

export const Styled = {
  MsgContainer,
  MsgContent,
  MsgAvatar,
  MsgText,
  MsgReactionsWrapper,
}