import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Video,
  Modal,
  Text,
  Box,
  Button as QiwaButton,
} from "@takamol/qiwa-design-system/components";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./HomePageVideoBanner.scss";
import Icon from "../Icon/Icon";
import { Image } from "@takamol/qiwa-design-system/components";
import VideoPoster from "./VideoPoster.png";
import { useTranslation, Trans } from "react-i18next";
import { MainContext } from "../contexts/context";

const HomePageVideoBanner = () => {
  const [showModal, setShowModal] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const { t } = useTranslation("translation", { keyPrefix: "videoBanner" });
  const { theme } = useContext(MainContext);

  const openModalButtonRef = useRef<HTMLButtonElement>(null);
  const openPlayButtonRef = useRef<HTMLButtonElement>(null);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleOpen = () => {
    setShowModal(true);
    setIsVideoPlaying(true);
    if (openModalButtonRef.current) openModalButtonRef.current.blur();
    if (openPlayButtonRef.current) openPlayButtonRef.current.blur();
  };

  const handleClose = () => {
    setShowModal(false);
    setIsVideoPlaying(false);
  };

  useEffect(() => {
    if (showModal) {
      setIsVideoPlaying(true);
    }
    if (!showModal) {
      setIsVideoPlaying(false);
    }
  }, [showModal]);

  return (
    <div className="home-page-video-banner">
      <Container className="home-page-video-banner-container">
        <Row>
          <Col lg={5} className="home-page-video-banner__left-col">
            <p className="home-page-video-banner__header">
              <Trans
                i18nKey="header"
                t={t}
                components={[<span key="highlighted-text"></span>]}
              />
            </p>
            <p className="home-page-video-banner__content">{t("content")}</p>
            {width > 991 && (
              <Button
                className={`home-page-video-banner__button`}
                onClick={handleOpen}
                ref={openModalButtonRef}
              >
                <div className="home-page-video-banner__icon-container">
                  <Icon
                    name="play"
                    viewBox={theme === "english" ? "0 0 32 24" : "0 -4 16 32"}
                    width="32px"
                    height="32px"
                    className="home-page-video-banner__icon"
                    testId="test-play-icon-watch-button"
                  ></Icon>
                </div>
                {t("watchButton")}
              </Button>
            )}
          </Col>
          <Col lg={6} className="home-page-video-banner__right-col">
            <button
              className="home-page-video-banner__image"
              onClick={handleOpen}
            >
              <Image
                altText="Meet new Qiwa Platform!"
                aspectRatio="16 / 9"
                src={VideoPoster}
                borderRadius={8}
                overlay="light"
              />
              <Button
                className="home-page-video-banner__play-video-img"
                onClick={handleOpen}
                ref={openPlayButtonRef}
                data-testid="test-button-play-video-img"
              >
                <Icon
                  name="play"
                  viewBox="0 0 24 24"
                  width="32px"
                  height="32px"
                  className="home-page-video-banner__player-icon"
                ></Icon>
              </Button>
            </button>
            {width < 992 && (
              <Button
                className="home-page-video-banner__button__full-width"
                onClick={handleOpen}
                ref={openModalButtonRef}
                data-testid="test-full-width-button"
              >
                <div className="home-page-video-banner__icon-container">
                  <Icon
                    name="play"
                    viewBox={theme === "english" ? "0 -4 32 32" : "0 -4 16 32"}
                    width="32px"
                    height="32px"
                    className="home-page-video-banner__icon"
                    testId="test-play-icon-watch-button-mobile"
                  ></Icon>
                </div>
                {t("watchButton")}
              </Button>
            )}
            <Modal
              hasCloseButton={true}
              isOpened={showModal}
              handleClose={handleClose}
              desktopWidth="wide"
              data-testid="modal"
            >
              <Modal.Body data-testid="modal-body">
                <Box gap={16}>
                  <Text
                    pe={8}
                    variant={["heading-xxs", "heading-xs"]}
                    weight="semibold"
                  >
                    {t("modalTitle")}
                  </Text>
                  <Text variant="body-l">{t("modalContent")}</Text>
                  <Video
                    videoSrc="https://vimeo.com/863059924"
                    altPosterDescription="Meet new Qiwa Platform!"
                    withIconCentered={true}
                    size="large"
                    poster={VideoPoster}
                    isPlaying={isVideoPlaying}
                  />
                </Box>
              </Modal.Body>
              <Modal.Footer>
                <QiwaButton onClick={handleClose}>
                  {t("closeButton")}
                </QiwaButton>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomePageVideoBanner;
