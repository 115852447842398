import React, { useContext,useEffect,useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../Breadcrumbs/Breadcrumbs";
import "./PermissionError.scss";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { MainContext } from "../../../contexts/context";
import HeroSearchInput from "../../../Search/HeroSearchInput/HeroSearchInput.tsx";
import { fetchErrorData } from "../../../../FetchError.js";
import { isEmpty } from "lodash";

function PermissionError({ code }) {
  const navigate = useNavigate();
  const { theme } = useContext(MainContext);
  const [errorData, setErrorData] = useState({});
  const { t } = useTranslation("translation", {
    keyPrefix: "errors.403error",
  });
  useEffect(() => {
    const fetchData = async () => {
      const fetchCode = [404,401,403].includes(code) ? `${code}?include=field_relevant_page` : code;
      const data = await fetchErrorData(fetchCode);
      if(localStorage.getItem("i18nextLng") === "ar" && !isEmpty( data?.data[1])) {
        setErrorData(data?.data[1]);
      }
      if (localStorage.getItem("i18nextLng") === "en" && !isEmpty( data?.data[0])) {
        setErrorData(data?.data[0]);
      }
    };

    fetchData();
  }, [code, localStorage.getItem("i18nextLng")]);
  const { attributes } = errorData;

  const refreshPage = () => {
    navigate(-1);
  };

  return (
    <Container fluid>
      <Row
        className={classnames({
          "permission-main": true,
          "permission-main--ltr": theme === "english",
          "permission-main--rtl": theme !== "english",
        })}
      >
        <Col className="permission-content">
          <Breadcrumbs />
          <p className="permission-header">{attributes?.field_label}</p>
          <p className="permission-error-code">
            {t("errorCode", { code })}
          </p>
          <p
            dangerouslySetInnerHTML={{
              __html: attributes?.field_description?.value,
            }}
          ></p>
        </Col>
      </Row>
      <Row
        className={classnames({
          "permission-search": true,
          "permission-search--ltr": theme === "english",
          "permission-search--rtl": theme !== "english",
        })}
      >
        <p className="permission-search-title">{t("searchTitle")}</p>
        <Col xl={6} className="permission-search-component">
          <HeroSearchInput
            placeholder={t("searchPlaceholder")}
            buttonText={t("buttonText")}
            isSaudiLabor={false}
            id="403-search-input-id"
          />
        </Col>
        <p className="permission-search-header"> {t("topicsHeader")}</p>
        <a href={`/${localStorage.getItem("i18nextLng")}/labor-law/non-saudis/hiring-non-saudis`}>{t("firstTopic")}</a>
        <a href={`/${localStorage.getItem("i18nextLng")}/business-owners/hire-employees/hiring-non-saudi-employees`}> {t("secondTopic")}</a>
        <a href={`/${localStorage.getItem("i18nextLng")}/business-owners/hire-employees/how-issue-work-permits-non-saudis`}>{t("thirdTopic")}</a>
        <a href={`/${localStorage.getItem("i18nextLng")}/business-owners/manage-current-employees/how-transfer-non-saudi-employees-between-your`}>{t("fourthTopic")}</a>
        <a href={`/${localStorage.getItem("i18nextLng")}/business-owners/manage-current-employees/how-renew-work-permits`}> {t("fifthTopic")}</a>
      </Row>
    </Container>
  );
}
PermissionError.defaultProps = {
  code: 403,
};
PermissionError.propTypes = {
  code: PropTypes.number,
};

export default PermissionError;


