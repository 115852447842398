import styled from 'styled-components';
import { respondAbove } from "@takamol/qiwa-design-system/styles/mediaQueries";

const FormWrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;

  form {
    width: 100%;
  }

  @media ${respondAbove.md} {
    max-width: 734px;
  }
`

const InputWrapper = styled.div<{$isAnswersPage?: boolean}>`
  position: relative;

  div[data-component="Field"] {
    gap: 12px;

    div[data-component="Box"]:last-child {
      div {
        height: auto;
      }
    }
  }

  &:focus-within {
    span[data-component="Icon"] {
      svg {
        path {
          stroke: rgba(10, 14, 20, 1);
          stroke-width: 0;
          fill: rgba(10, 14, 20, 1);
        }
      }
    }
  }

  input {
    background-color: #fafafa;
    border-radius: 16px;
    padding: 16px 44px;
    height: max-content;
    border-color: #E6E6E7;
    box-shadow: ${props => props.$isAnswersPage ? '0px -8px 16px 0px rgba(0, 0, 0, 0.06)' : 'unset'};
  }

  input:not(:disabled):focus-visible {
    outline: none;
    box-shadow: none;
    border-color: rgb(10, 14, 20);
  }

  span[data-component="Icon"] {
    svg {
      path {
        stroke: rgba(147, 151, 156, 1);
        stroke-width: 0;
        fill: rgba(147, 151, 156, 1);
      }
    }
  }
`

const StarIconWrapper = styled.span`
  height: 20px;
  width: 20px;

  svg {
    position: absolute;
    bottom: 20px;
    inset-inline-start: 15px;
    z-index: 100;
  }
`

export const Styled = {
  FormWrapper,
  InputWrapper,
  StarIconWrapper,
}