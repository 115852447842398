import React, { useState, useEffect, useRef, useContext } from "react";
import AIAssistantHeader from "./components/AIAssistantHeader/AIAssistantHeader";
import AIAssistantDialogMsg from "./components/AIAssistantDialogMsg/AIAssistantDialogMsg";
import {
  ToastContextProvider,
  ToastStacking,
  IconButton,
} from "@takamol/qiwa-design-system/components";
import AIAssistantInput from "./components/AIAssistantInput";
import { ArrowDown } from "@takamol/qiwa-design-system/icons";
import { Styled } from "./AIAssistantAnswers.styles";
import { MainContext } from "../../contexts/context";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { manageQuestionsStorage } from "./shared/utils/manageQuestionsStorage";
import { Remarkable } from "remarkable";
import { linkify } from "remarkable/linkify";

const md = new Remarkable().use(linkify);

export type AIDialogMessage = {
  author: string;
  msg: string;
  uuid?: string;
  order?: number;
  isComplete?: boolean;
};

const AIAssistantAnswers = () => {
  const { theme } = useContext(MainContext);
  const [messages, setMessages] = useState<AIDialogMessage[]>([]);
  const [hasChatStarted, setHasChatStarted] = useState(false);
  const [showScrollBtn, setShowScrollBtn] = useState<boolean>(false);
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false);
  const [socket, setSocket] = useState<any>(null);
  const [isAiAnswerLoading, setIsAiAnswerLoading] = useState<boolean>(false);
  const [prevTheme, setPrevTheme] = useState(
    localStorage.getItem("i18nextLng")
  );
  const answersRef: any = useRef(null);
  const answersBottomRef: any = useRef(null);
  const { t } = useTranslation("translation", {
    keyPrefix: "aiAssistant",
  });
  const navigate = useNavigate();
  let inactivityTimeout: any = undefined;
  const INACTIVITY_DELAY = 1200;

  useEffect(() => {
    // Initialize chat on component mount
    initializeChat();

    window.addEventListener("beforeunload", () => {
      localStorage.removeItem("aiAssistantDialog");
    });

    return () => {
      localStorage.removeItem("aiAssistantDialog");
    };
  }, []);

  useEffect(() => {
    const storage = localStorage.getItem("aiAssistantDialog");
    if (storage == null) {
      navigate(`/${localStorage.getItem("i18nextLng")}/ai-assistant`, {
        replace: true,
      });
    } else {
      const parsedStorage = JSON.parse(storage);
      setMessages(parsedStorage);

      setTimeout(() => {
        if (socket && socket.readyState === WebSocket.OPEN ) {
          if(messages[messages.length - 1].author === "user") {
            addMsg(messages[messages.length - 1].msg, true);
          }
        }
      }, 1200);
    }
  }, [socket?.readyState]);

  useEffect(() => {
    const currentTheme = localStorage.getItem("i18nextLng");
    if (prevTheme !== currentTheme) {
      navigate(`/${currentTheme}/ai-assistant`, { replace: true });
    }
    setPrevTheme(currentTheme);
  }, [theme]);

  const initializeChat = async () => {
    try {
      const crypto = window.crypto;
      const randomArray = new Uint32Array(1);
      crypto.getRandomValues(randomArray);
      const convId = randomArray[0].toString();
      connectWebSocket(convId);
    } catch (error) {
      console.error("Error initializing chat:", error);
    }
  };

  const connectWebSocket = (conversationId: string) => {
    const wsUrl = `${
      process.env.REACT_APP_SOCKET_FULL_URL
    }?lang=${localStorage.getItem("i18nextLng")}`;
    const newSocket = new WebSocket(wsUrl);

    newSocket.onopen = () => {
      console.log("WebSocket connected");
    };

    newSocket.onmessage = (event) => {
      const data = event.data;
      resetInactivityTimer();

      if (data === "[DONE]") {
        console.log("Response complete");
      } else {
        appendToLastMessage(data);
      }
    };

    newSocket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    newSocket.onclose = (event) => {
      console.log("WebSocket disconnected", event.reason);
      setTimeout(() => connectWebSocket(conversationId), 3000); // Reconnect after delay
    };

    setSocket(newSocket);
  };

  useEffect(() => {
    if (answersRef.current !== null) {
      answersBottomRef.current?.scrollIntoView({
        behavior: "instant",
        block: "end",
      });
      answersRef.current.addEventListener("scroll", () => {
        const scrollHeight = answersRef.current.scrollHeight;
        const height = answersRef.current.clientHeight;
        const maxScrollTop = scrollHeight - height - 5;
        if (answersRef.current.scrollTop < maxScrollTop) {
          setShowScrollBtn(true);
        } else {
          setShowScrollBtn(false);
        }
      });
    }
  }, [answersBottomRef.current, answersRef.current?.scrollHeight]);

  const appendToLastMessage = (data: any) => {
    setIsAiAnswerLoading(false);
    setHasChatStarted((prevChatStarted: boolean) => !prevChatStarted);
    setMessages((prevMessages: AIDialogMessage[]) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      const updatedBotText = lastMessage.msg + data;

      // Regular expression to match <source>...</source> tags
      const sourceRegex = /<source>(.*?)<\/source>/g;

      // Remove all <source>...</source> tags from the botText
      const cleanedBotText = updatedBotText.replace(sourceRegex, "");

      return [
        ...prevMessages.slice(0, prevMessages.length - 1),
        {
          author: "ai",
          msg: cleanedBotText,
          uuid: lastMessage.uuid,
          order: lastMessage.order,
          isComplete: false,
        },
      ];
    });
  };

  const scrollDialogToBottom = () => {
    answersBottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const addMsg = async (msg: string, isFirst?: boolean) => {
    setIsAiAnswerLoading(true);
    setHasChatStarted((prevChatStarted: boolean) => !prevChatStarted);
    setIsInputDisabled(true);

    if (!msg) return;

    try {
      try {
        if (socket && socket.readyState === WebSocket.OPEN) {
          if (!isFirst) {
            manageQuestionsStorage.addQuestion({
              author: "user",
              msg: msg,
              uuid:
                messages.length > 0 ? messages[messages.length - 1].uuid : "",
            });
          }
          manageQuestionsStorage.addQuestion({
            author: "ai",
            msg: "",
            uuid: messages.length > 0 ? messages[messages.length - 1].uuid : "",
            isComplete: false,
          });
          setMessages((prevMessages: AIDialogMessage[]) => {
            return isFirst
              ? [
                  ...prevMessages,
                  {
                    author: "ai",
                    msg: "",
                    uuid:
                      messages.length > 0
                        ? messages[messages.length - 1].uuid
                        : "",
                    order: messages.length + 1,
                    isComplete: false,
                  },
                ]
              : [
                  ...prevMessages,
                  {
                    author: "user",
                    msg: msg,
                    uuid:
                      messages.length > 0
                        ? messages[messages.length - 1].uuid
                        : "",
                    order: messages.length + 1,
                  },
                  {
                    author: "ai",
                    msg: "",
                    uuid:
                      messages.length > 0
                        ? messages[messages.length - 1].uuid
                        : "",
                    order: messages.length + 1,
                    isComplete: false,
                  },
                ];
          });
          socket.send(msg);
        } else {
          console.error(
            "WebSocket is not connected, refresh and just wait 2 seconds"
          );
        }
      } catch (error) {
        console.error("Error sending message: ", error);
      }
    } catch (error) {
      console.error("Error sending message to API:", error);
      setMessages((prevChat: AIDialogMessage[]) => [
        ...prevChat,
        {
          author: "ai",
          msg: "Failed to get a response. Try again.",
          uuid: prevChat.length > 0 ? prevChat[prevChat.length - 1].uuid : "",
          order: prevChat.length + 1,
          isComplete: false,
        },
      ]);
    }
  };

  function resetInactivityTimer() {
    clearTimeout(inactivityTimeout);
    inactivityTimeout = setTimeout(() => {
      completeLastMessage();
    }, INACTIVITY_DELAY);
  }

  const completeLastMessage = () => {
    setIsAiAnswerLoading(false);
    setIsInputDisabled(false);
    setHasChatStarted((prevChatStarted: boolean) => !prevChatStarted);
    console.log("Completing the last message due to inactivity.");

    setMessages((prevMessages: AIDialogMessage[]) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      manageQuestionsStorage.modifyLastMsg({
        ...lastMessage,
        msg: formatAssistantResponse(lastMessage.msg),
      });

      return [
        ...prevMessages.slice(0, prevMessages.length - 1),
        {
          ...lastMessage,
          msg: formatAssistantResponse(lastMessage.msg),
          isComplete: true,
        },
      ];
    });
  };

  function formatAssistantResponse(response: string) {
    let formatted = response;

    // Converter to convert markdown to HTML and replace string-links with proper <a> tag
    formatted = md.render(formatted);

    setHasChatStarted((prevChatStarted: boolean) => !prevChatStarted);

    return formatted;
  }

  useEffect(() => {
    scrollDialogToBottom();
  }, [hasChatStarted]);

  return (
    <ToastContextProvider>
      <Styled.AnswersPageWrapper data-testid="ai-assistant-answers-test-id">
        <AIAssistantHeader blur={true} />
        <Styled.AnswersContainer ref={answersRef}>
          {messages.map((msg: AIDialogMessage, index: number) => {
            return (
              <AIAssistantDialogMsg
                key={msg.author + "-" + msg.order}
                msg={msg.msg}
                author={msg.author}
                isLoading={
                  msg.author === "user" ? undefined : isAiAnswerLoading
                }
                isComplete={msg.isComplete}
              />
            );
          })}
          {showScrollBtn && (
            <Styled.ScrollBtnWrapper $isRtl={theme !== "english"}>
              <IconButton
                iconComponent={ArrowDown}
                variant="neutral_ghost"
                size={24}
                onClick={scrollDialogToBottom}
              />
            </Styled.ScrollBtnWrapper>
          )}
          <Styled.AnswersBottom ref={answersBottomRef} />
        </Styled.AnswersContainer>
        <Styled.QuestionInputWrapper $theme={theme}>
          <AIAssistantInput
            isAnswersPage
            addMsg={addMsg}
            isInputDisabled={isInputDisabled}
          />
          <Styled.FooterText>{t("footerText")}</Styled.FooterText>
        </Styled.QuestionInputWrapper>
      </Styled.AnswersPageWrapper>
      <ToastStacking />
    </ToastContextProvider>
  );
};

export default AIAssistantAnswers;
