import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./Order.scss";
import { MainContext } from "../../../../contexts/context";
import classnames from "classnames";

const OrderComponent = ({ order }) => {
  const { theme } = useContext(MainContext);
  if (!order) {
    return null;
  }

  return (
    <div className="order-container">
      <div
        className={classnames({
          "order-container__number": true,
          "order-container__number--ltr": theme === "english",
          "order-container__number--rtl": theme !== "english",
        })}
      >
        <p className="order-container__order">{order}</p>
      </div>
    </div>
  );
};
OrderComponent.propTypes = {
  order: PropTypes.string,
};

export default OrderComponent;
