import React from "react";
import PropTypes from "prop-types";

interface ShowMoreLessButtonProps {
  isExpanded: boolean;
  onShowMore: (categoryKey: string) => void;
  onShowLess: (categoryKey: string) => void;
  categoryKey: string;
  t: (key: string) => string; // Translation function
}

const ShowMoreLessButton: React.FC<ShowMoreLessButtonProps> = ({
  isExpanded,
  onShowMore,
  onShowLess,
  categoryKey,
  t,
}) => {
  return (
    <div className="labor-education-materials__show-more-less-container">
      {!isExpanded ? (
        <button
          className="labor-education-materials__show-more"
          onClick={() => onShowMore(categoryKey)}
        >
          {t("showMore")}
        </button>
      ) : (
        <button
          className="labor-education-materials__show-less"
          onClick={() => onShowLess(categoryKey)}
        >
          {t("showLess")}
        </button>
      )}
    </div>
  );
};

ShowMoreLessButton.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onShowMore: PropTypes.func.isRequired,
  onShowLess: PropTypes.func.isRequired,
  categoryKey: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired, // Function for translations
};

export default ShowMoreLessButton;
