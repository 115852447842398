import styled from "styled-components";
import { respondAbove } from "@takamol/qiwa-design-system/styles/mediaQueries";

const HeaderContainer = styled.div<{$withBlur?: boolean}>`
  padding: 12px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.$withBlur ? 'rgba(255, 255, 255, 0.20)' : 'transparent'};
  backdrop-filter: ${props => props.$withBlur ? 'blur(20px)' : 'unset'};

  .language-switcher.nav-item.dropdown {
    .nav-link {
      border: none;
      display: flex;

      &:hover {
        border: none !important;
      }
    }
  }

  @media ${respondAbove.lg} {
    padding: 24px 48px;
  }
`

const HeaderLogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const HeaderSublogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  padding: 6px 12px;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.06);
  font-weight: 600;
  font-size: 18px;
  color: rgba(32, 77, 136, 1);

  .icon {
    width: 20px;
    height: 20px;

    @media ${respondAbove.lg} {
      width: 20px;
      height: 20px;
    }
  }

  span {
    position: relative;
    top: -4px;
    text-transform: uppercase;
    font-size: 8px;
    font-weight: 700;

    @media ${respondAbove.lg} {
      font-size: 8px;
      top: -5px;
    }
  }

  @media ${respondAbove.lg} {
    font-size: 18px;
  }
`

const MainLogoWrapper = styled.div`
  margin: 0;
  padding: 0;

  .icon {
    width: 48px;
    height: 42px;

    @media ${respondAbove.lg} {
      width: 64px;
      height: 56px;
    }
  }
`

export const Styled = {
  HeaderContainer,
  HeaderLogoContainer,
  HeaderSublogoContainer,
  MainLogoWrapper,
}